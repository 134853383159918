import CreateUserForm from "./CreateUserForm";
import PageSection from "../../../ui/PageSection";
import {Breadcrumb, Card, Col, Container, Row} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import Page from "../../../ui/Page";

const CreateUserPage = () => {
    return(
        <Page>
            <PageSection
                title={"Crea Utente"}/>
            <Container fluid>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <LinkContainer to={"/users"}>
                                <Breadcrumb.Item>
                                    Utenti
                                </Breadcrumb.Item>
                            </LinkContainer>
                            <Breadcrumb.Item href="#" active>
                                Nuovo
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className={"card-primary"}>
                            <Card.Header>
                                <Card.Title>
                                    Nuovo Utente
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text as={"div"}>
                                    <CreateUserForm/>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

export default CreateUserPage;