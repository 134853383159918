import {configureStore} from '@reduxjs/toolkit'
import {reducers} from "./reducers";
import {persistStore, PERSIST} from "redux-persist";

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [PERSIST],
            },
        }),
});

export const persistor = persistStore(store);
