import { createSlice } from '@reduxjs/toolkit';
import {addWindowClass, removeWindowClass} from "../../utils/helpers";
import "../../styles/ui.scss";
import "react-toastify/dist/ReactToastify.css"


const initialState = {
    darkMode : window.matchMedia('(prefers-color-scheme: dark)').matches,
    desktop : window.innerWidth > 500,
    sidebar : window.innerWidth > 500
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState: initialState,
    reducers: {
        toggleDarkMode: (state) => {
            state.darkMode = !state.darkMode;
            if (state.darkMode) {
                addWindowClass('dark-mode');
            } else {
                removeWindowClass('dark-mode');
            }
        },
        screenResize: (state) => {
            state.desktop = window.innerWidth > 500;
            state.sidebar = window.innerWidth > 500;
        },
        changeSidebar: (state) => {
            state.sidebar = !state.sidebar;
        }
    }
});

export const { toggleDarkMode, screenResize,changeSidebar } = uiSlice.actions;

export default uiSlice.reducer;