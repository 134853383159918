import {Formik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import * as yup from "yup";
import CustomButton from "../../ui/CustomButton";

function UserSearchFilter({ setDataLoaded, originalData }) {

    const schema = yup.object().shape({
        username: yup.string()
            .min(1, 'Richiesto almeno un carattere')
    });

    const filterUsers = (values) => {
        let users = { users: originalData.users };
        for (const [key, value] of Object.entries(values)) {
            users = {
                ...{
                    users: users.users.filter(user =>
                        String(user[key]).toLowerCase()
                            .includes(value.toLowerCase())
                    )
                }
            };
        }
        if (users.users.length === 0) {
            toast.error("Nessun utente trovato", {autoClose: 2000});
        }
        setDataLoaded(users);
    }

    return (
        <Formik
            validationSchema={ schema }
            initialValues= {
                {
                    username: '',
                    role: '',
                }
            }
            onSubmit= { (values) => filterUsers(values) }
            onReset={ () => setDataLoaded(originalData) }
        >
            {
                ({ handleSubmit, handleReset, handleChange, touched, errors, values }) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit} className={"ml-4 mr-4"}>
                            <Row>
                                <Col xs={"auto"} md={6}>
                                    <Form.Group controlId="validationUsername">
                                        <Form.Label>
                                            Username
                                        </Form.Label>
                                        <Form.Control
                                            name={"username"}
                                            value={values.username}
                                            onChange={handleChange}
                                            isInvalid={touched.username && errors.username}
                                        />
                                        <Form.Control.Feedback type={"invalid"}>
                                            { errors.username }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={"auto"} md={6}>
                                    <Form.Group controlId="validationRole">
                                        <Form.Label>
                                            Ruolo
                                        </Form.Label>
                                        <Form.Select
                                            name={"role"}
                                            value={values.role}
                                            onChange={handleChange}
                                            isInvalid={touched.role && errors.role}
                                        >
                                            <option value="">Scegliere una ruolo da cercare</option>
                                            <option value="ADMIN">Admin</option>
                                            <option value="RECRUITER">Recruiter</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type={"invalid"}>
                                            { errors.role }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className={"text-right mt-3"}>
                                <CustomButton
                                    text={" Cerca"}
                                    type={"button"}
                                    className={"btn-success btn-md ml-2"}
                                    action={handleSubmit}
                                />
                                <CustomButton
                                    text={"Cancella"}
                                    type={"button"}
                                    className={"btn-error btn-md ml-2"}
                                    action={handleReset}
                                />
                            </div>
                        </Form>
                    </>
                )
            }
        </Formik>
    );
}

export default UserSearchFilter;