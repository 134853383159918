import {Breadcrumb, Card, Col, Container, Row} from "react-bootstrap";
import PageSection from "../../ui/PageSection";
import axios from "axios";
import {properties} from "../../../properties";
import {useEffect, useState} from "react";
import UsersTable from "./UsersTable";
import "../../../styles/pages.scss";
import {FaUserPlus} from "react-icons/fa";
import CustomButton from "../../ui/CustomButton";
import Page from "../../ui/Page";
import TablePlaceholder from "../database/TablePlaceholder";
import UserSearchFilter from "./UserSearchFilter";
import Restricted from "../../ui/Restricted";

const UsersPage = () => {

    const [dataLoaded, setDataLoaded] = useState({users:[]});
    const [loadedUsers, setLoadedUsers] = useState({users:[]});
    const [isLoading, setIsLoading] = useState(true);

    const loadUsers = () => {
        setIsLoading(true);
        axios.get(properties.basePath + "/users/all.php")
            .then(res => {
                const response = res.data;
                setLoadedUsers({...loadedUsers, ...response});
                setDataLoaded({...dataLoaded, ...response});
                setIsLoading(false);
            })
    }

    const handleDeleteUser = (userId) => {
        axios.delete(properties.basePath + "/users/delete.php?userId=" + userId)
            .then(res => {
                loadUsers();
            })
    }

    useEffect(() => {loadUsers()}, []);

    return (
        <Page>
            <PageSection
                title={"Utenti"} />
            <Container fluid>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item href="#" active>
                                Utenti
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Restricted permission={"createUser"}>
                            <div className={"text-right mb-2"}>
                                <CustomButton
                                    text={" Crea Utente"}
                                    icon={<FaUserPlus/>}
                                    className={"btn-success btn-md mb-2"}
                                    path={"/users/create"}
                                />
                            </div>
                        </Restricted>

                        <Card className={"card-primary"}>
                            <Card.Header>
                                <Card.Title>
                                    Cerca Utente
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text as={"div"}>
                                    <UserSearchFilter
                                        setDataLoaded={setDataLoaded}
                                        originalData={loadedUsers} />
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className={"card-primary"}>
                            <Card.Header>
                                <Card.Title>
                                    Lista Utenti
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text as={"div"}>
                                    { isLoading ?
                                        <TablePlaceholder/>
                                        :
                                    <UsersTable
                                        loadedUsers={dataLoaded}
                                        handleDeleteUser={handleDeleteUser} />
                                    }
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Page>
    );

}

export default UsersPage;