import { Card } from "react-bootstrap";

const Footer = () => {
    return(
      <>
          <Card className={"footer ml-2 mr-2"}>
              <Card.Body>
                  <Card.Text as={"div"}>
                      <span className="float-right d-none d-sm-block"><b>Version</b> {process.env.REACT_APP_VERSION}</span>
                      <strong>#ProudToBeRingmaster</strong>

                  </Card.Text>
              </Card.Body>
          </Card>
      </>
    );
}

export default Footer;