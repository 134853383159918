import PageSection from "../../ui/PageSection";
import {Breadcrumb, Card, Col, Container, Form, Row} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {properties} from "../../../properties";
import {useEffect, useState} from "react";
import {notifySuccess} from "../../services/notification-service";
import {FaPencilAlt} from "react-icons/fa";
import Page from "../../ui/Page";

const EditCategoryPage = () => {

    const [categoryLoaded, setCategoryLoaded] = useState({});
    const navigate = useNavigate();
    const { categoryId } = useParams();

    const loadCategory = () => {
        axios.get(properties.basePath + "/categories/getById.php?categoryId=" + categoryId)
            .then(res => {
                const response = res.data;
                setCategoryLoaded({...categoryLoaded, ...response});
            })
    }

    const handleEditSubmit = (newCategoryName) => {
        const formData = new FormData();
        formData.append('categoryId', categoryId);
        formData.append('name', newCategoryName);

        axios({
            method: "post",
            url: properties.basePath + "/categories/edit.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                notifySuccess("Categoria modificata con successo!");
                navigate("/database");
            })
            .catch(function (response) {
                //handle error
            });
    }

    useEffect(() => {loadCategory()}, []);

    return (
        <Page>
            <PageSection title={"Database - Modifica Categoria"}/>
            <Container fluid>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <LinkContainer to={"/database"}>
                                <Breadcrumb.Item>Database</Breadcrumb.Item>
                            </LinkContainer>
                            <Breadcrumb.Item active>Modifica Categoria</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card
                            key={"database-card-data-loaded"}
                            className={"card-primary"}>
                            <Card.Header key={"database-card-data-loaded-header"}>
                                <Card.Title>
                                    Modifica Categoria
                                </Card.Title>
                            </Card.Header>
                            <Card.Body key={"database-card-data-body"}>
                                <Card.Text
                                    key={"database-card-data-text"}
                                    as={"div"}>
                                    <Form>
                                        <Form.Label>Nome Categoria</Form.Label>
                                        <Form.Control
                                            id={"newCategoryName"}
                                            type={"text"}
                                            defaultValue={categoryLoaded.name}
                                        />
                                        <div
                                            onClick={() => {
                                                handleEditSubmit(document.getElementById("newCategoryName").value)
                                            }}
                                            className={"btn btn-info btn-sm mt-3"}>
                                            <FaPencilAlt /> Modifica
                                        </div>
                                    </Form>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

export default EditCategoryPage;