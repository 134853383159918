import {Alert, Card, Placeholder, Spinner} from "react-bootstrap";
import UserActivity from "./UserActivity";
import {FaClock} from "react-icons/fa";

const UsersLastActivities = (props) => {
    return (
        <>
            <Card className={"card-primary"}>
                <Card.Header>
                    <Card.Title>
                        Attività recenti
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {props.isLoading ? (
                        <div>
                            <Placeholder as={Card.Text} animation="glow">
                                <Placeholder xs={7} />
                                <Placeholder xs={4} />
                                <Placeholder xs={6} />
                                <Placeholder xs={8} />
                            </Placeholder>
                        </div>
                        ) : (
                        <Card.Text as={"div"}>
                            {
                                props.activities.length === 0 ?
                                    <Alert key={"test"} variant={"danger"}>
                                        Nessuna attività recente!
                                    </Alert>
                                    :
                                    // <div className="timeline">
                                    //     {
                                            props.activities.map((activity) => {
                                                return (
                                                    <UserActivity
                                                        key={"activity-" + activity.username + "-" + activity.activityDate}
                                                        activity={ activity } />
                                                );
                                            })
                                    //     }
                                    // </div>
                            }
                        </Card.Text>
                        )
                    }

                </Card.Body>
            </Card>
        </>
    );
}

export default UsersLastActivities;