import {showErrorNotification, showSuccessNotification} from "../../store/reducers/notification-reducer";
import {store} from "../../store/store";

const notifySuccess = (text) => {
    store.dispatch(showSuccessNotification({message:text}))
    return true;
};

const notifyError = (text) => {
    store.dispatch(showErrorNotification({message:text}))
    return true;
};

export {
    notifySuccess,
    notifyError
};