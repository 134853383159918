import {Form, InputGroup} from "react-bootstrap";
import {FaEye, FaEyeSlash, FaLock, FaPencilAlt, FaUser, FaUserTag} from "react-icons/fa";
import CustomButton from "../../../ui/CustomButton";
import axios from "axios";
import {properties} from "../../../../properties";
import {notifyError, notifySuccess} from "../../../services/notification-service";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import * as yup from "yup";
import {useSelector} from "react-redux";
import {RiArrowGoBackLine} from "react-icons/ri";

const EditUserForm = ({ userId, userLoaded }) => {

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

    const loggedUser = useSelector((state) => state.auth.username);

    const handleEditSubmit = (values) => {
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('role', values.role);
        formData.append('newPassword', values.newPassword);

        axios({
            method: "post",
            url: properties.basePath + "/users/edit.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                notifySuccess("Utente modificato con successo!");
                navigate("/users");
            })
            .catch(function (response) {
                //handle error
                notifyError("Errore durante la modifica dell'utente!");
            });
    }

    const schema = yup.object().shape({
        role: yup.string()
            .oneOf(['ADMIN', 'RECRUITER'], "Selezionare un ruolo")
            .required("Campo obbligatorio"),
        newPassword: yup.string()
            .min(6, 'Richiesti almeno 6 caratteri'),
        newPasswordRepeat: yup.string()
            .min(6, 'Richiesti almeno 6 caratteri')
            .test('password-match', 'Le due password non coincidono',
                function() {
                return this.parent.newPassword === this.parent.newPasswordRepeat;
            })
    });

    return (
        <Formik
            validationSchema={ schema }
            onSubmit={(values) => {handleEditSubmit(values)}}
            enableReinitialize={true}
            initialValues={
                {
                    role: userLoaded.role,
                    newPassword: '',
                    newPasswordRepeat: '',
                }
            }
        >
            {
                ({ handleSubmit, handleChange, touched, errors, values }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Label>
                            <FaUser /> Username
                        </Form.Label>
                        <Form.Control
                            type={"text"}
                            value={userLoaded.username}
                            readOnly={true}/>

                        {
                            loggedUser !== userLoaded.username &&
                                <Form.Group controlId="validationFormik03">
                                    <Form.Label className={"mt-3"}>
                                        <FaUserTag/> Ruolo
                                    </Form.Label>
                                    <Form.Select
                                        name={"role"}
                                        value={ values.role }
                                        onChange={ handleChange }
                                        isValid={touched.role && !errors.role}
                                        isInvalid={touched.role && errors.role}
                                    >
                                        <option value={"NONE"}>Scegliere un ruolo</option>
                                        <option value={"ADMIN"}>ADMIN</option>
                                        <option value={"RECRUITER"}>RECRUITER</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.role }
                                    </Form.Control.Feedback>
                                </Form.Group>
                        }

                        <Form.Group controlId="passwordValidation">
                            <Form.Label className={"mt-3"}>
                                <FaLock /> Nuova Password
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text
                                    id="newPasswordInputGroup"
                                    onClick={() => {
                                        setShowPassword(!showPassword)
                                    }}>
                                    {
                                        showPassword ? <FaEyeSlash /> : <FaEye />
                                    }
                                </InputGroup.Text>
                                <Form.Control
                                    name={"newPassword"}
                                    type={showPassword ? "text" : "password"}
                                    onChange={ handleChange }
                                    value={ values.newPassword }
                                    placeholder={"Old password"}
                                    isValid={ touched.newPassword && !errors.newPassword && values.newPassword === values.newPasswordRepeat }
                                    isInvalid={ touched.newPassword && errors.newPassword && values.newPassword !== values.newPasswordRepeat}
                                />
                                <Form.Control.Feedback type={"invalid"}>
                                    { errors.newPassword }
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="passwordConfirmationValidation">
                            <Form.Label className={"mt-3"}>
                                <FaLock /> Ripeti Password
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text
                                    id="passwordConfigrmationInputGroup"
                                    onClick={() => {
                                        setShowPasswordRepeat(!showPasswordRepeat)
                                    }}>
                                    {
                                        showPasswordRepeat ? <FaEyeSlash /> : <FaEye />
                                    }
                                </InputGroup.Text>
                                <Form.Control
                                    name={"newPasswordRepeat"}
                                    type={showPasswordRepeat ? "text" : "password"}
                                    onChange={ handleChange }
                                    value={ values.newPasswordRepeat }
                                    placeholder={"Old password"}
                                    isValid={ touched.newPasswordRepeat && !errors.newPasswordRepeat && values.newPassword === values.newPasswordRepeat }
                                    isInvalid={ touched.newPasswordRepeat && errors.newPasswordRepeat && values.newPassword !== values.newPasswordRepeat }
                                />
                                <Form.Control.Feedback type={"invalid"}>
                                    { errors.newPasswordRepeat }
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <div className={"text-right mt-3"}>
                            <CustomButton
                                icon={<RiArrowGoBackLine/>}
                                path={-1}
                                text={" Indietro"}
                                className={"btn-info mr-2"}
                                type={"button"}
                            />
                            <CustomButton
                                text={" Modifica"}
                                icon={<FaPencilAlt />}
                                className={"btn-info mr-2"}
                                type={"submit"}
                            />
                        </div>
                    </Form>
                )
            }
        </Formik>
    );
}

export default EditUserForm;