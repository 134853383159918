import {HashRouter, Route, Routes} from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import LoginPage from "../pages/login/LoginPage";
import PrivateRoute from "./PrivateRoute";
import AuthorizedRoutes from "./AuthorizedRoutes";
import {store} from "../../store/store";
import {addWindowClass} from "../../utils/helpers";

const MainRoute = () => {

    if (store.getState().ui.darkMode) addWindowClass('dark-mode')

    return (
        <>
            <HashRouter>
                <Routes>
                    <Route path={"/login"} element={<PublicRoutes />}>
                        <Route path={"/login"} element={<LoginPage />} />
                    </Route>
                    <Route path={"/*"} element={<PrivateRoute />}>
                        <Route path={"*"} element={<AuthorizedRoutes />} />
                    </Route>
                </Routes>
            </HashRouter>
        </>
    );

}

export default MainRoute;