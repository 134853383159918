import {createSlice} from '@reduxjs/toolkit';


const initialState = {
    authenticated: false,
    userId: null,
    username: null,
    role: null,
    permissions:[]
}

export const loginSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuthentication : (state, {payload}) => {
            state.authenticated = true;
            state.userId = payload.userId;
            state.username = payload.username;
            state.role = payload.role;
            state.permissions = payload.permissions;
        },
        executeLogin: (state, {payload}) => {
        },
        executeLogout: (state) => {
            state.authenticated = false;
            state.username = null;
        },
    },
});

export const { setAuthentication, executeLogout, executeLogin } = loginSlice.actions;

export default loginSlice.reducer;