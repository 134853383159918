import Page from "../../ui/Page";
import PageSection from "../../ui/PageSection";
import {Accordion, AccordionItem, Alert, Badge, Breadcrumb, Card, Col, Container, Row} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import ReactQuill from "react-quill";
import {FaCheckCircle, FaMinus, FaPlus} from "react-icons/fa";
import CustomButton from "../../ui/CustomButton";
import {FaCircleXmark} from "react-icons/fa6";
import HiringQuestionAnswersView from "./HiringQuestionAnswersView";

const ViewHiringQuestions = () => {

    let hiringData = useSelector(state => state.hiring);

    const [iconCollapse,setIconCollapse]  = useState(false);
    const [isTransitioning,setIsTransitioning]  = useState(false);

    const handleClick = () => {
        if (!isTransitioning) {
            setTimeout(()=>{
                setIsTransitioning(true);
                setIconCollapse(!iconCollapse);
            },1)
            setTimeout(() => {
                setIsTransitioning(false);
            }, 400);
        }
    };

    return(
        <Page>
            <PageSection title={"Hiring - Visualizza Domande"} />
            <Container fluid>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <LinkContainer to={"/hiring"}>
                                <Breadcrumb.Item>
                                    Hiring
                                </Breadcrumb.Item>
                            </LinkContainer>
                            <Breadcrumb.Item active>
                                Visualizza Domande
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            hiringData.length === 0 ?
                                <Alert variant={"danger"}>
                                    Nessuna domanda caricata!
                                </Alert>
                                :
                                hiringData.map((data) => {
                                    return(
                                        <Card className={"card-primary"} key={`card-category-${data.category.name}`}>
                                            <Card.Header key={`card-header-category-${data.category.name}`}>
                                                <Card.Title key={`card-title-category-${data.category.name}`}>
                                                    {data.category.name}
                                                </Card.Title>
                                                <div key={`card-tools-category-${data.category.name}`} className="card-tools">
                                                    <CustomButton
                                                        key={`card-collapse-btn-category-${data.category.name}`}
                                                        icon={!iconCollapse ? <FaMinus /> : <FaPlus />}
                                                        className={`btn-info btn-sm mr-2 ${isTransitioning ? 'disabled' : ''}`}
                                                        collapse={"collapse"}
                                                        action={handleClick}
                                                    />
                                                </div>
                                            </Card.Header>
                                            <Card.Body key={`card-body-category-${data.category.name}`}>
                                                <Card.Text key={`card-text-category-${data.category.name}`} as={"div"}>
                                                    {
                                                        data.questions.map((question, index) => {
                                                            return (
                                                                <HiringQuestionAnswersView
                                                                    key={`question-visualizer-${index + 1}`}
                                                                    question={question}
                                                                    index={index}
                                                                />
                                                            );
                                                        })
                                                    }
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    );
                                })
                        }
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

export default ViewHiringQuestions;