import {Breadcrumb, Col, Container, Dropdown, InputGroup, Row} from "react-bootstrap";
import HiringQuestions from "./HiringQuestions";
import PageSection from "../../ui/PageSection";
import axios from "axios";
import {properties} from "../../../properties";
import {useEffect, useState} from "react";
import "../../../styles/pages.scss";
import {TbCategoryPlus} from "react-icons/tb";
import {FaPlus} from "react-icons/fa";
import {FaFilePdf} from "react-icons/fa6";
import CustomButton from "../../ui/CustomButton";
import Page from "../../ui/Page";
import {useDispatch, useSelector} from "react-redux";
import {removeData} from "../../../store/reducers/hiring-reducer";
import {VscPreview} from "react-icons/vsc";

const HiringPage = () => {
    let questionsLoaded = useSelector(state => state.hiring);
    let alreadyLoadedCategory = questionsLoaded.map(question => {return question.category});
    const dispatch = useDispatch()
    const [categories, setCategories] = useState({categories:[]});
    const [loadedCategories, setLoadedCategories] = useState({categories:[]});
    const [newCategorySelected, setNewCategorySelected] = useState({id: -1, questions:[],name:"Seleziona una categoria"});

    const handleRemove = (obj) => {
        dispatch(removeData(obj));
        let removeCategory = loadedCategories.categories.filter(item => item.id !== obj.category.id);
        setLoadedCategories(prevState => { return {...prevState,...{categories: removeCategory}}});

    }

    const addCategoryToLoaded = (categoryToLoad) => {
        //no category selected
        if (categoryToLoad.name === "Seleziona una categoria") {
            return;
        }
        //category already loaded
        if(!loadedCategories.categories.some(item => item.id === categoryToLoad.id)) {
                let copy = loadedCategories;
                copy.categories.push(categoryToLoad);
                setLoadedCategories(prevState => ({...prevState, ...copy}));
                setNewCategorySelected(prevState => ({...prevState,...{id: -1, questions:[],name:"Seleziona una categoria"}}))
        }
    }


    const loadCategories = () => {
        if(questionsLoaded.length !== 0){
            alreadyLoadedCategory.map(question => {
                const {id,name} = question;
                addCategoryToLoaded({id,name});
            })
        }
        axios.get(properties.basePath + "/categories/all.php")
            .then(res => {
                const response = {categories: res.data.categories.filter(item => item.questions !== 0)};
                // Filtra gli oggetti presenti in loadedCategories ma non in response (categorie eliminate dal db)
                let categoryDeleted = loadedCategories.categories.filter((item) => !response.categories.some((subItem) => subItem.id === item.id)) || [];
                if(categoryDeleted.length !== 0){
                    categoryDeleted.forEach(ele => {
                        handleRemove({category:ele})
                    })
                }
                let availableQuestion = {...categories, ...response}.categories.filter((item) => !loadedCategories.categories.some((subItem) => subItem.id === item.id));
                setCategories(prevState => ({...prevState, categories: availableQuestion}));
            })
    }

    useEffect(() => {loadCategories()}, [loadedCategories]);

    return (
        <Page>
            <PageSection title={"Hiring"} />
            <Container fluid>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item active>
                                Hiring
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col className={"text-left"}>
                        <InputGroup>
                        <Dropdown>
                            <Dropdown.Toggle variant="info" id="dropdown-basic" className={"btn-primary"} style={{minWidth:"50%"}}>
                                <TbCategoryPlus /> { newCategorySelected.name }
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{minWidth:"50%"}}>
                                {
                                    categories.categories.length === 0?
                                        <Dropdown.Item>Tutte le categorie sono state utilizzate</Dropdown.Item>
                                        :
                                        categories.categories.map((category) => {
                                            return(
                                                <Dropdown.Item key={category.id} onClick={() => {setNewCategorySelected(prevState => ({...prevState,...category}))}}>
                                                    { category.name }
                                                </Dropdown.Item>
                                            );
                                        })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                            <InputGroup.Text
                                className={"btn-success"}
                                onClick={() => addCategoryToLoaded(newCategorySelected)}
                            >
                                <FaPlus/>
                            </InputGroup.Text>
                        </InputGroup>
                    </Col>
                    <Col className={"text-right"}>
                        <CustomButton
                            icon={<VscPreview/>}
                            text={" Visualizza"}
                            className={"btn-warning btn-md mb-2"}
                            path={`/hiring/view`}
                        />
                        <CustomButton
                            icon={<FaFilePdf/>}
                            text={" esporta PDF"}
                            className={"btn-warning btn-md mb-2 ml-2"}
                            path={`/hiring/pdf`}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <HiringQuestions
                            loadedCategories={loadedCategories}
                            remove={handleRemove}/>
                    </Col>
                </Row>
            </Container>
        </Page>
    );

}

export default HiringPage;