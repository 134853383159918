import {Placeholder, Table} from "react-bootstrap";

const TablePlaceholder = () => {
    return (
        <Table className={"table-striped"}>
            <tbody>
            <tr>
                <td>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} size="lg"/>
                    </Placeholder>
                </td>
                <td>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} size="lg"/>
                    </Placeholder>
                </td>
            </tr>
            <tr>
                <td>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} size="lg"/>
                    </Placeholder>
                </td>
                <td>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} size="lg"/>
                    </Placeholder>
                </td>
            </tr>

            </tbody>
        </Table>
    )
}

export default TablePlaceholder;