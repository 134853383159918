import {Form} from "react-bootstrap";
import ManageQuestionAnswers from "./ManageQuestionAnswers";
import {properties} from "../../../../properties";
import {notifySuccess} from "../../../services/notification-service";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import * as yup from "yup";
import {axiosInstance} from "../../../services/interceptor/axiosInstance";
import {FaStream} from "react-icons/fa";
import {MdOutlineTextSnippet} from "react-icons/md";

const CreateQuestionForm = ({ selectedCategory, questionId, questionLoaded }) => {

    const navigate = useNavigate();

    const handleCreateQuestion = (values) => {
        let questionBody;
        const questionType = values.questionType;

        if(questionType === "SINGLE") {
            questionBody = {
                questionTitle: values.questionTitle.trim(),
                questionLevel: values.questionLevel,
                question: values.question,
                answers: [{answerId: questionLoaded.answers[0]?.id, answer:  values.answer1.trim()}]
            }
        } else if (questionType === "MULTIPLE") {
            questionBody = {
                questionTitle: values.questionTitle.trim(),
                questionLevel: values.questionLevel,
                question: values.question,
                answers: [
                    { answerId: questionLoaded.answers[0]?.id, answer: values.answer1.trim() },
                    { answerId: questionLoaded.answers[1]?.id, answer: values.answer2.trim() },
                    { answerId: questionLoaded.answers[2]?.id, answer: values.answer3.trim() },
                    { answerId: questionLoaded.answers[3]?.id, answer: values.answer4.trim() }
                ]
            }
        }

        const formData = new FormData();
        let body = {
            category: selectedCategory,
            question: questionBody["question"],
            questionTitle: questionBody["questionTitle"],
            questionLevel: questionBody["questionLevel"],
            questionType: questionType,
            answers: questionBody["answers"]
        };

        if(questionId){
            body["questionId"] = questionId;
        }

        formData.append('body', JSON.stringify(body));

        axiosInstance({
            method: "post",
            url: properties.basePath + `/questions/${questionId ? "edit" : "create"}.php`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                notifySuccess(`Domanda ${questionId ? "modificata" : "creata"} con successo!`);
                navigate("/database/"+selectedCategory);
            })
            .catch(function (response) {
                //handle error
            });
    }

    const schema = yup.object().shape({
        questionTitle: yup.string()
            .min(1, "Richiesto almeno un carattere")
            .max(255, "Ammessi massimo 255 caratteri")
            .required("Campo obbligatorio"),
        questionType: yup.string()
            .oneOf(["SINGLE", "MULTIPLE"], "Selezionare una tipologia di domanda")
            .required("Campo obbligatorio"),
        questionLevel: yup.string()
            .oneOf(["JUNIOR", "MIDDLE", "SENIOR"], "Selezionare livello della domanda")
            .required("Campo obbligatorio"),
        question: yup.string()
            .test('len', 'Campo Obbligatorio', val => {
                if (val === undefined) return false
                return val.replace(/<(.|\n)*?>/g, '').trim().length > 0
            }),
        answer1: yup.string()
            .required("Campo obbligatorio"),
        answer2: yup.string(),
        answer3: yup.string(),
        answer4: yup.string()
    });

    return (
        <Formik
            validationSchema={ schema }
            onSubmit= {
                (values) => {
                    handleCreateQuestion(values);
                }
            }
            enableReinitialize={true}
            initialValues= {
                {
                    questionTitle: questionLoaded.questionTitle ? questionLoaded.questionTitle : "",
                    questionType: questionLoaded.questionType ? questionLoaded.questionType : "NONE",
                    questionLevel: questionLoaded.questionLevel ? questionLoaded.questionLevel : "NONE",
                    question: questionLoaded.question ? questionLoaded.question : "",
                    answer1: questionLoaded.answers[0] ? questionLoaded.answers[0].answer : "",
                    answer2: questionLoaded.answers[1] ? questionLoaded.answers[1].answer : "",
                    answer3: questionLoaded.answers[2] ? questionLoaded.answers[2].answer : "",
                    answer4: questionLoaded.answers[3] ? questionLoaded.answers[3].answer : "",
                }
            }
        >
            {
                ({ handleSubmit, handleChange, touched, errors, values }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="questionTypeValidation">
                            <Form.Label>
                                <FaStream className={"mr-2"}/>
                                Tipo Domanda
                            </Form.Label>
                            <Form.Select
                                name={"questionType"}
                                className={"mt-2"}
                                value={ values.questionType }
                                onChange={ handleChange }
                                isInvalid={ touched.questionType && errors.questionType }>
                                <option value="NONE">Scegliere una tipologia di domanda da creare</option>
                                <option value="SINGLE">Risposta Singola</option>
                                <option value="MULTIPLE">Risposta Multipla</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                { errors.questionType }
                            </Form.Control.Feedback>
                        </Form.Group>

                        <br/>

                        <Form.Group controlId="questionLevelValidation">
                            <Form.Label>
                                <FaStream className={"mr-2"}/>
                                Livello Domanda
                            </Form.Label>
                            <Form.Select
                                name={"questionLevel"}
                                className={"mt-2"}
                                value={ values.questionLevel }
                                onChange={ handleChange }
                                isInvalid={ touched.questionLevel && errors.questionLevel }>
                                <option value="NONE">Scegliere il livello della domanda da creare</option>
                                <option value="JUNIOR">Junior</option>
                                <option value="MIDDLE">Middle</option>
                                <option value="SENIOR">Senior</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                { errors.questionLevel }
                            </Form.Control.Feedback>
                        </Form.Group>

                        <br/>

                        <Form.Group controlId="questionTitleValidation">
                            <Form.Label>
                                <MdOutlineTextSnippet className={"mr-2"}/>
                                Titolo domanda
                            </Form.Label>
                            <Form.Control
                                name={"questionTitle"}
                                className={"mt-2"}
                                value={ values.questionTitle }
                                onChange={ handleChange }
                                isInvalid={ touched.questionTitle && errors.questionTitle }>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                { errors.questionTitle }
                            </Form.Control.Feedback>
                        </Form.Group>

                        <br/>

                        <ManageQuestionAnswers
                            handleCreateQuestion={ handleSubmit }
                            questionLoaded={ questionLoaded }
                            questionType = { values.questionType }
                            formik={{
                                handleChange: handleChange,
                                touched: touched,
                                errors: errors,
                                values: values
                            }}
                        />
                    </Form>
                )
            }

        </Formik>
    );
}

export default CreateQuestionForm;