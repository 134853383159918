export const properties =
    {
        basePath: process.env.REACT_APP_SERVICE_BASE_PATH,
        data: {
            pagination: {
                defaultPageSize: 10,
                pageSizes: [5, 10, 25, 50, 100]
            }
        },
        hiring: {
            categories: {
                maxQuestions: 5
            }
        }
    };