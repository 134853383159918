import PageSection from "../../../ui/PageSection";
import {Breadcrumb, Card, Col, Container, Row} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {properties} from "../../../../properties";
import Page from "../../../ui/Page";
import EditUserForm from "./EditUserForm";
import Restricted from "../../../ui/Restricted";

const EditUserPage = () => {

    const [userLoaded, setUserLoaded] = useState({username:''});
    const { userId } = useParams();

    const loadUser = () => {
        axios.get(properties.basePath + "/users/getById.php?id=" + userId)
            .then(res => {
                const response = res.data;
                setUserLoaded({...userLoaded, ...response});
            })
    }

    useEffect(() => {loadUser()}, []);

    return(
        <Page>
            <PageSection title={"Modifica Utente"}/>
            <Container fluid>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <Restricted permission={"viewUsersPage"}>
                                <LinkContainer to={"/users"}>
                                    <Breadcrumb.Item>
                                        Utenti
                                    </Breadcrumb.Item>
                                </LinkContainer>
                            </Restricted>
                            <Breadcrumb.Item active>
                                Modifica Utente
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card
                            key={"database-card-data-loaded"}
                            className={"card-primary"}>
                            <Card.Header
                                key={"database-card-data-loaded-header"}>
                                <Card.Title>
                                    Modifica Utente
                                </Card.Title>
                            </Card.Header>
                            <Card.Body
                                key={"database-card-data-body"}>
                                <Card.Text
                                    key={"database-card-data-text"}
                                    as={"div"}>
                                    <EditUserForm
                                        userId={userId}
                                        userLoaded={userLoaded} />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

export default EditUserPage;