import {Alert, Card, Modal, Table} from "react-bootstrap";
import {FaFolder, FaMinus, FaPencilAlt, FaPlus, FaTrash} from "react-icons/fa";
import CustomButton from "../../ui/CustomButton";
import CreateCategoryForm from "./createCategory/CreateCategoryForm";
import TablePlaceholder from "./TablePlaceholder";
import {useEffect, useState} from "react";
import {store} from "../../../store/store";
import DataPagination from "../../ui/DataPagination";
import Restricted from "../../ui/Restricted";
import CategorySearchFilter from "./SearchFilter/CategorySearchFilter";

const DatabaseDataLoaded = ({data, handleCreateCategory, handleDeleteCategory, isLoading}) => {

    const [dataToShow, setDataToShow] = useState([]);
    const [dataLoaded, setDataLoaded] = useState({categories:[]});
    const [categoryToDelete, setCategoryToDelete] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [iconCollapse,setIconCollapse]  = useState(false);
    const [isTransitioning,setIsTransitioning]  = useState(false);

    const handleModalClose = () => {
        setCategoryToDelete({...categoryToDelete, ...{}});
        setShowDeleteModal(false);
    }

    const handleModalShow = (category) => {
        setCategoryToDelete({...categoryToDelete, ...category});
        setShowDeleteModal(true);
    }

    const handleClick = () => {
        if (!isTransitioning) {
            setTimeout(()=>{
                setIsTransitioning(true);
                setIconCollapse(!iconCollapse);
            },1)
            setTimeout(() => {
                setIsTransitioning(false);
            }, 400);
        }
    };

    useEffect(()=> {
        setDataLoaded({...dataLoaded, ...data});
    }, [data.categories]);

    return (
        <>
            <Card className={"card-primary"}>
                <Card.Header>
                    <Card.Title>
                        Cerca Categoria
                    </Card.Title>
                    <div className={"card-tools"}>
                        <CustomButton
                            icon={!iconCollapse ? <FaMinus/> : <FaPlus/>}
                            className={`btn-info btn-sm mr-2 ${isTransitioning ? 'disabled' : ''}`}
                            collapse={"collapse"}
                            action={handleClick}
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card.Text as={"div"}>
                        <CategorySearchFilter
                            originalData={data}
                            setDataLoaded={setDataLoaded}
                        />
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card
                key={"database-card-data-loaded"}
                className={"card-primary"}>
                <Card.Header key={"database-card-data-loaded-header"} >
                    <Card.Title>
                        Categorie
                    </Card.Title>
                </Card.Header>
                <Card.Body key={"database-card-data-body"} >
                    <Card.Text key={"database-card-data-text"} as={"div"}>
                        <Restricted permission={"createCategory"}>
                            <CreateCategoryForm
                                handleCreateCategory={handleCreateCategory}
                            />
                        </Restricted>
                        {
                            isLoading ?
                                <TablePlaceholder/>
                            :
                            dataToShow.length === 0 ?
                                    <Alert variant={"danger"}>
                                        <h4>Nessuna categoria esistente</h4>
                                    </Alert>
                                :
                                    <>
                                        <Table className={"table-striped"}>
                                            <thead>
                                                <tr>
                                                    <th>Categoria</th>
                                                    <th style={{textAlign: "center"}}>Domande</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                dataToShow.map((data) => {
                                                    return (
                                                        <tr key={'row-' + data.id + '-' + data.name}>
                                                            <td key={'label-' + data.id + '-' + data.name}>
                                                                {data.name}
                                                            </td>
                                                            <td key={'questions-' + data.id + '-' + data.name}
                                                                style={{textAlign: "center"}}>
                                                                {data.questions}
                                                            </td>
                                                            <td key={'functions-' + data.id + '-' + data.name}
                                                                style={{textAlign: "right"}}>
                                                                <CustomButton
                                                                    icon={<FaFolder/>}
                                                                    className={"btn-primary btn-sm mr-2"}
                                                                    path={`/database/${data.name}`}
                                                                />
                                                                <Restricted permission={"editCategory"}>
                                                                    <CustomButton
                                                                        icon={<FaPencilAlt/>}
                                                                        className={"btn-info btn-sm mr-2"}
                                                                        path={`/database/${data.id}/edit`}
                                                                    />
                                                                </Restricted>
                                                                <Restricted permission={"deleteCategory"}>
                                                                <CustomButton
                                                                    icon={<FaTrash/>}
                                                                    className={"btn-danger btn-sm mr-2"}
                                                                    // action={props.handleDeleteCategory}
                                                                    action={() => handleModalShow(
                                                                        {
                                                                            id: data.id,
                                                                            name: data.name
                                                                        }
                                                                    )}
                                                                    param={data.id}
                                                                />
                                                                </Restricted>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                    </>

                        }
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <DataPagination
                        dataLoaded={dataLoaded.categories}
                        dataToShow={dataToShow}
                        setDataToShow={setDataToShow}
                    />
                </Card.Footer>
            </Card>

            <Modal show={showDeleteModal} onHide={handleModalClose}>
                <Modal.Header closeButton className={(store.getState().ui.darkMode ? "dark-mode" : "")}>
                    <Modal.Title>Eliminare la categoria ?</Modal.Title>
                </Modal.Header>
                <Modal.Body className={(store.getState().ui.darkMode ? "dark-mode" : "")}>
                    Tutte le domande all'interno della categoria verranno perse.
                </Modal.Body>
                <Modal.Footer className={(store.getState().ui.darkMode ? "dark-mode" : "")}>
                    <CustomButton
                        text={"Chiudi"}
                        key={"close-category-confirmation"}
                        className={"btn-secondary btn-md mr-2"}
                        action={handleModalClose}
                    />
                    <CustomButton
                        text={" Elimina"}
                        key={"delete-category-confirmation"}
                        icon={<FaTrash/>}
                        className={"btn-danger btn-md mr-2"}
                        action={
                            () => {
                                handleDeleteCategory(categoryToDelete.id);
                                handleModalClose();
                            }
                        }
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DatabaseDataLoaded;