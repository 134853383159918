import {Card, Col, Container, ListGroup, Row} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {Outlet} from "react-router-dom";

const HomePage = () => {
    return (
        <>
            <Outlet/>
        </>
    );
}

export default HomePage;