import {Form, InputGroup} from "react-bootstrap";
import {FaPlus} from "react-icons/fa";
import {Formik} from "formik";
import CustomButton from "../../../ui/CustomButton";
import * as yup from "yup";

const CreateCategoryForm = (props) => {

    const schema = yup.object().shape({
        newCategoryName: yup.string()
            .min(1, 'Richiesto almeno 1 carattere')
            .max(35, 'Richiesti al massimo 35 caratteri')
            .required('Campo obbligatorio')
    });

    return(
        <Formik
            validationSchema={ schema }
            onSubmit={
                (values, { resetForm }) => {
                    props.handleCreateCategory(values.newCategoryName.trim());
                    resetForm();
                }
            }
            initialValues={
                {
                    newCategoryName: '',
                }
            }
        >
            {
                ({ handleSubmit, handleChange, touched, errors, values }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="validationFormik01">
                            <InputGroup className={"mb-3"}>
                                <Form.Control
                                    name={"newCategoryName"}
                                    type="text"
                                    onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                                    value={values.newCategoryName}
                                    onChange={handleChange}
                                    isValid={touched.newCategoryName && !errors.newCategoryName}
                                    isInvalid={touched.newCategoryName && errors.newCategoryName}
                                    placeholder="Inserisci il nome della nuova categoria" />
                                <CustomButton
                                    text={" Crea"}
                                    type={"button"}
                                    icon={<FaPlus/>}
                                    className={"btn-success ml-2"}
                                    action={handleSubmit}
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.newCategoryName }
                                </Form.Control.Feedback>
                            </InputGroup>

                        </Form.Group>
                    </Form>
                )
            }
        </Formik>
    );
}

export default CreateCategoryForm;