import {Routes, Route} from 'react-router-dom';
import DatabasePage from "../pages/database/DatabasePage";
import EditCategoryPage from "../pages/database/EditCategoryPage";
import DatabaseCategoryPage from "../pages/database/DatabaseCategoryPage";
import QuestionPage from "../pages/database/createQuestion/QuestionPage";
import ViewQuestionPage from "../pages/database/ViewQuestionPage";

const DatabaseRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<DatabasePage />} />
                <Route path="/:categoryId/edit" element={<EditCategoryPage />} />
                <Route path="/:selectedCategory" element={<DatabaseCategoryPage />} />
                <Route path="/:selectedCategory/create" element={<QuestionPage />} />
                <Route path="/:selectedCategory/:questionId" element={<ViewQuestionPage />} />
                <Route path="/:selectedCategory/:questionId/edit" element={<QuestionPage />} />
            </Routes>
        </>
    );
}

export default DatabaseRoutes;
