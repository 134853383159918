import "../../styles/pages.scss";
import Footer from "../Footer";


const Page = ({ children, classname }) => {
    return (
        <div className={"page " + classname}>
            <div className="content">
                {children}
            </div>
            <Footer />
        </div>
    );
}

export default Page;
