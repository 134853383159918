import {Route, Routes} from "react-router-dom";
import MainPage from "../pages/MainPage";
import HomePage from "../pages/HomePage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import DatabaseRoute from "./DatabaseRoute";
import UsersRoutes from "./UsersRoute";
import HiringRoute from "./HiringRoute";

const AuthorizedRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<MainPage/>}>
                    <Route path="/" element={<HomePage />}>
                        <Route path="/" element={<DashboardPage />} />
                        <Route path="/dashboard" element={<DashboardPage />} />
                        <Route path="/hiring/*" element={<HiringRoute />} />
                        <Route path='/database/*' element = {<DatabaseRoute/>} />
                        <Route path="/users/*" element={<UsersRoutes />} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
}

export default AuthorizedRoutes;