import { createSlice } from '@reduxjs/toolkit';

const initialState = []

export const hiringSlice = createSlice({
    name: 'hiring',
    initialState: initialState,
    reducers: {
        addData: (state, { payload }) => {
            const existingData = state.find(data => data.category.id === payload.category.id);
            if (existingData) {
                state.map(element => {
                    if (element.category.id === payload.category.id) {
                        element.questions = [...payload.questions];
                    }
                    return element;
                });
            } else {
                state.push(payload);
            }
        },
        removeData: (state,{payload}) => {
            state = state.filter(data => data.category.id !== payload.category.id);
            return state
        },
        addQuestion: (state, {payload}) => {
            const existingData = state.find(data => data.category.id === payload.categoryId);
            if (existingData) {
                state.map(element => {
                    if (element.category.id === payload.categoryId) {
                        element.questions.push(payload.newQuestion);
                    }
                    return element;
                });
            }
        },
        removeQuestion: (state, {payload}) => {
            const existingData = state.find(data => data.category.id === payload.categoryId);
            if (existingData) {
                state.map(element => {
                    if (element.category.id === payload.categoryId) {
                        element.questions = element.questions.filter(question => question.id !== payload.questionId);
                    }
                    return element;
                });
            }
        },
        refreshQuestion: (state, {payload}) => {
            const existingData = state.find(data => data.category.id === payload.categoryId);
            if (existingData) {
                state.map(element => {
                    if (element.category.id === payload.categoryId) {
                        element.questions[payload.index] = payload.newQuestion;
                    }
                    return element;
                });
            }
            return state;
        }
    }
});

export const {
    addData,
    removeData,
    addQuestion,
    removeQuestion,
    refreshQuestion} = hiringSlice.actions;

export default hiringSlice.reducer;