import {Form} from "react-bootstrap";
import {useEffect, useMemo, useRef, useState} from "react";
import {FaPlus} from "react-icons/fa6";
import {RiArrowGoBackLine} from "react-icons/ri"
import {useNavigate} from "react-router-dom";
import CustomButton from "../../../ui/CustomButton";
import {VscSaveAs} from "react-icons/vsc";
import {FaQuestion, FaReply, FaReplyAll} from "react-icons/fa";
import ReactQuill, {Quill} from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {Field} from "formik";


const ManageQuestionAnswers = ({ handleCreateQuestion, questionLoaded, questionType, formik }) => {

    const navigate = useNavigate();
    let questionRef = useRef();
    let answer1 = useRef();
    let answer2 = useRef();
    let answer3 = useRef();
    let answer4 = useRef();

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ font: [] }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline",],
                    [{ color: [] }, { background: [] }],
                    [{ script: "sub" }, { script: "super" }],
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [
                        { indent: "-1" },
                        { indent: "+1" },
                        { align: [] },
                    ],
                    [{ size: ["small", false, "large", "huge"] }],
                    ["link", "clean"]
                ],

                history: {
                    delay: 500,
                    maxStack: 100,
                    userOnly: true,
                },
            },
        })
    )
    

    useEffect(()=> {
        if(questionLoaded.answers.length > 0){
            if(questionType === "SINGLE"){
                questionRef.current.value = questionLoaded.question;
                answer1.current.value = questionLoaded.answers[0]?.answer || "";
        }else if(questionType === "MULTIPLE"){
                questionRef.current.value = questionLoaded.question;
                answer1.current.value = questionLoaded.answers[0]?.answer  || "";
                answer2.current.value = questionLoaded.answers[1]?.answer || "";
                answer3.current.value = questionLoaded.answers[2]?.answer || "";
                answer4.current.value = questionLoaded.answers[3]?.answer || "";
            }
        }
    },[questionType]);

    return (
        <>
            <Form.Group controlId="questionValidation">
                <Form.Label>
                    <FaQuestion /> Domanda
                </Form.Label>

                <Field name="question" onChange={formik.handleChange} value={formik.values.question} >
                    {({ field }) =>
                        <ReactQuill
                            ref={questionRef}
                            value={field.value}
                            onChange={field.onChange(field.name)}
                            modules={modules}
                            />}
                </Field>

                {formik.errors.question && formik.touched.question && (
                    <div className="invalid-feedback" style={{display:"block"}}>{formik.errors.question}</div>
                )}

            </Form.Group>
            {
                questionType === "SINGLE" && (
                    <>
                        <Form.Group controlId="singleResponseValidation">
                            <Form.Label className={"mt-2"}>
                                <FaReply /> Risposta
                            </Form.Label>
                            <Form.Control
                                name={"answer1"}
                                className={"mt-2"}
                                as="textarea"
                                ref={answer1}
                                value={ formik.values.answer1 }
                                onChange={ formik.handleChange }
                                isInvalid={ formik.touched.answer1 && formik.errors.answer1 }
                                rows={4} />
                            <Form.Control.Feedback type="invalid">
                                { formik.errors.answer1 }
                            </Form.Control.Feedback>
                        </Form.Group>
                    </>
                )
            }
            {
                questionType === "MULTIPLE" && (
                    <>
                        <Form.Label className={"mt-2"}>
                            <FaReplyAll /> Risposte
                        </Form.Label>
                        <div className={"ml-3"}>
                            <Form.Group controlId="multipleResponseValidation1">
                                <div className="callout callout-success">
                                    <Form.Control
                                        name={"answer1"}
                                        className={"mt-2"}
                                        as="textarea"
                                        rows={2}
                                        ref={answer1}
                                        value={ formik.values.answer1 }
                                        onChange={ formik.handleChange }
                                        isInvalid={ formik.touched.answer1 && formik.errors.answer1 }/>
                                    <Form.Control.Feedback type="invalid">
                                        { formik.errors.answer1 }
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="multipleResponseValidation2">
                                <div className="callout callout-danger">
                                    <Form.Control
                                        name={"answer2"}
                                        className={"mt-2"}
                                        as="textarea"
                                        rows={2}
                                        ref={answer2}
                                        value={ formik.values.answer2 }
                                        onChange={ formik.handleChange }
                                        isInvalid={ formik.touched.answer2 && formik.errors.answer2 }/>
                                    <Form.Control.Feedback type="invalid">
                                        { formik.errors.answer2 }
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="multipleResponseValidation3">
                                <div className="callout callout-danger">
                                    <Form.Control
                                        name={"answer3"}
                                        className={"mt-2"}
                                        as="textarea"
                                        rows={2}
                                        ref={answer3}
                                        value={ formik.values.answer3 }
                                        onChange={ formik.handleChange }
                                        isInvalid={ formik.touched.answer3 && formik.errors.answer3 }/>
                                    <Form.Control.Feedback type="invalid">
                                        { formik.errors.answer3 }
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="multipleResponseValidation4">
                                <div className="callout callout-danger">
                                    <Form.Control
                                        name={"answer4"}
                                        className={"mt-2"}
                                        as="textarea"
                                        rows={2}
                                        ref={answer4}
                                        value={ formik.values.answer4 }
                                        onChange={ formik.handleChange }
                                        isInvalid={ formik.touched.answer4 && formik.errors.answer4 }/>
                                    <Form.Control.Feedback type="invalid">
                                        { formik.errors.answer4 }
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </div>
                    </>
                )
            }
            <div className={"text-right mt-3"}>
                <CustomButton
                    icon={<RiArrowGoBackLine/>}
                    path={-1}
                    text={" Indietro"}
                    className={"btn-info mr-2"}
                    type={"button"}
                />
                <CustomButton
                    icon={questionLoaded.answers.length > 0 ? <VscSaveAs/> : <FaPlus/>}
                    text={questionLoaded.answers.length > 0 ? " Salva Modifiche" : " Crea"}
                    className={"btn-success"}
                    type={"submit"}
                />
            </div>
        </>
    );
}

export default ManageQuestionAnswers;