import {Dropdown, Pagination, Stack} from "react-bootstrap";
import {useEffect, useState} from "react";
import {IoIosArrowDown} from "react-icons/io";
import {properties} from "../../properties";
import {useSelector} from "react-redux";

const DataPagination = ({dataLoaded, dataToShow, setDataToShow}) => {

    const [activePage, setActivePage] = useState(1);
    const [pageElements, setPageElements] = useState(properties.data.pagination.defaultPageSize);
    const [paginationItems, setPaginationItems] = useState([]);

    const desktop = useSelector((state) => state.ui.desktop)

    const generateDataToShow = () => {
        const idxStart = pageElements * (activePage - 1);
        const idxStop = (pageElements * activePage) - 1;

        let dataToShowItems = [];
        for (let number = idxStart; number <= idxStop; number++) {
            if(dataLoaded.length > number) {
                dataToShowItems.push(dataLoaded[number]);
            }
        }
        setDataToShow(dataToShowItems);
    }

    const generatePaginationItem = () => {
        let items = [];
        items.push(<Pagination.First key={"pagination-first-page"}/>);
        for (let number = 1; number <= Math.ceil(dataLoaded.length / pageElements); number++) {
            items.push(
                <Pagination.Item
                    key={"pagination-page-" + number}
                    active={number === activePage}
                    onClick={() => setActivePage(number)}
                >
                    {number}
                </Pagination.Item>,
            );
        }
        items.push(<Pagination.Last key={"pagination-last-page"}/>)
        setPaginationItems(items);
        generateDataToShow();
    }

    useEffect(() => {
        generatePaginationItem();
    }, [pageElements, activePage, dataLoaded]);

    return(
        <Stack key={"pagination-stack"} direction="horizontal" gap={3}>
            {
                desktop ?
                    <div className="p-2 me-auto text-left">
                        {
                            dataLoaded.length === 0 ? (
                                "Nessun elemento"
                            )
                            :
                                `Da ${(pageElements * (activePage - 1)) + 1} a ${(pageElements * activePage) > dataLoaded.length ? dataLoaded.length : (pageElements * activePage)} di ${dataLoaded.length} elementi`
                        }

                    </div>
                    :
                    <div className="p-2 me-auto text-left"></div>
            }

            <div className="p-2">
                <Pagination key={"pagination-main"}>
                    {paginationItems}
                </Pagination>
            </div>

            {
                desktop ?
                    <div className="p-2 ms-auto">
                        Righe per pagina
                        <Dropdown key={"pagination-dropdown-pagesize"} className="d-inline mx-2">
                            <Dropdown.Toggle id="dropdown-autoclose-true">
                                {pageElements} <IoIosArrowDown/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    properties.data.pagination.pageSizes.map((size) => {
                                        return (
                                            <Dropdown.Item key={"pagination-dropdown-pagesize-"+size} onClick={() => setPageElements(size)}>
                                                {size}
                                            </Dropdown.Item>
                                        );
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    :
                    <div className="p-2 ms-auto"></div>
            }
        </Stack>
    );

}

export default DataPagination;