import {Button, Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {notifySuccess} from "../../../services/notification-service";
import {FaLock, FaPlus, FaUser, FaUserTag} from "react-icons/fa";
import {Formik} from "formik";
import * as yup from 'yup';
import {axiosInstance} from "../../../services/interceptor/axiosInstance";

const CreateUserForm = () => {

    const navigate = useNavigate();

    const handleCreateUser = (username, password, role) => {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('role', role);

        axiosInstance({
            method: "post",
            url: "/users/create.php",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })
            .then(function () {
                //handle success
                notifySuccess("Utente creato con successo!");
                navigate("/users");
            })
            .catch(function (response) {
                //handle error
            });
    }

    const schema = yup.object().shape({
        username: yup.string()
            .min(5, 'Richiesti almeno 5 caratteri')
            .required('Campo obbligatorio'),
        password: yup.string()
            .min(8, 'Richiesti almeno 8 caratteri')
            .required('Campo obbligatorio'),
        role: yup.string()
            .oneOf(['ADMIN', 'RECRUITER'], "Selezionare un ruolo")
            .required("Campo obbligatorio")
    });

    return (
        <>
            <Formik
                validationSchema={ schema }
                onSubmit={
                    (values) => handleCreateUser(values.username.trim(),
                                                                    values.password,
                                                                    values.role)
                }
                initialValues={
                    {
                        username: '',
                        password: '',
                        role: 'NONE'
                    }
                }
            >
                {
                    ({ handleSubmit, handleChange, touched, errors, values }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="validationFormik01">
                                <Form.Label>
                                    <FaUser/> Username
                                </Form.Label>
                                <Form.Control
                                    name={"username"}
                                    type={"text"}
                                    value={values.username}
                                    onChange={handleChange}
                                    isValid={touched.username && !errors.username}
                                    isInvalid={touched.username && errors.username}
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.username }
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="validationFormik02">
                                <Form.Label className={"mt-2"}>
                                    <FaLock/> Password
                                </Form.Label>
                                <Form.Control
                                    name={"password"}
                                    type={"password"}
                                    value={values.password}
                                    onChange={handleChange}
                                    isValid={touched.password && !errors.password}
                                    isInvalid={touched.password && errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                    { errors.password }
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="validationFormik03">
                                <Form.Label className={"mt-2"}>
                                    <FaUserTag/> Ruolo
                                </Form.Label>
                                <Form.Select
                                    name={"role"}
                                    value={values.role}
                                    onChange={handleChange}
                                    isValid={touched.role && !errors.role}
                                    isInvalid={touched.role && errors.role}
                                >
                                    <option value={"NONE"}>Scegliere un ruolo</option>
                                    <option value={"ADMIN"}>ADMIN</option>
                                    <option value={"RECRUITER"}>RECRUITER</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    { errors.role }
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button
                                className={"btn-success mt-4"}
                                type={"submit"}
                            >
                                <FaPlus
                                    size={20}
                                    style={{verticalAlign: "text-bottom"}}
                                    className={"global-class-name mr-2"}/>
                                Crea
                            </Button>
                        </Form>
                    )
                }
            </Formik>
        </>
    );
}

export default CreateUserForm;