import {Routes, Route} from 'react-router-dom';
import HiringPage from "../pages/hiring/HiringPage";
import Pdf from "../pages/hiring/Pdf";
import ViewHiringQuestions from "../pages/hiring/ViewHiringQuestions";

const HiringRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<HiringPage />} />
                <Route path={"/view"} element={<ViewHiringQuestions/>} />
                <Route path="/pdf" element={<Pdf />} />
            </Routes>
        </>
    );
}

export default HiringRoutes;
