import React, {useEffect, useState} from 'react';
import '../../styles/sidebar.scss';
import {MenuItem} from "./MenuItem";
import {FaUsers} from "react-icons/fa6";
import {useLocation} from "react-router-dom";
import {FaDatabase} from "react-icons/fa";
import {AiFillDashboard} from "react-icons/ai";
import {RiUserSearchFill} from "react-icons/ri";
import Restricted from "../ui/Restricted";


export const Sidebar = ({expandedStart, innerRef }) => {

    let menuItems = [
        {
            name: "Dashboard",
            iconName: AiFillDashboard,
            type: "solid",
            permission: "viewDashboardPage"
        },
        {
            name: "Hiring",
            iconName: RiUserSearchFill,
            type: "solid",
            permission: "viewHiringPage"
        },
        {
            name: "Users",
            iconName: FaUsers,
            type: "solid",
            permission: "viewUsersPage"
        },
        {
            name: "Database",
            iconName: FaDatabase,
            type: "solid",
            permission: "viewDatabasePage"
            // subMenu:[{
            //     name: "Kuhn Poker",
            //     iconName: GiPokerHand,
            //     type: "solid",
            //     subMenu:[{
            //         name: "Tables",
            //         iconName: SiAirtable,
            //         type: "solid",
            //     }]
            // }, {
            //     name: "Chess",
            //     iconName: FaChessKnight ,
            //     type: "solid",
            //     subMenu:[{
            //         name: "Tables",
            //         iconName: SiAirtable,
            //         type: "solid",
            //     }]
            // }, {
            //     name: "Metarace",
            //     iconName: GiTrojanHorse ,
            //     type: "solid",
            //     subMenu:[{
            //         name: "Races",
            //         iconName: IoCarSportOutline,
            //         type: "solid",
            //     },{
            //         name: "Race Formats",
            //         iconName: AiOutlineOrderedList,
            //         type: "solid",
            //     }]
            // },
            // ]
        }
    ];

    // Current page path. It is needed for the navigation and is used as the "active" item.
    let path = useLocation().pathname
    // Indicates the page we're on in the sidebar.
    const [active, setActive] = useState(path);
    // If the sidebar is expanded or not.
    const [expanded, setExpanded] = useState(expandedStart);
    // Indicates if the sidebar is ready (fully open). This is needed to show the texts only when the sidebar is ready.
    const [ready, setReady] = useState(false);
    // This let takes count of the level of the menuItem (0 = Main menu, 1 = First SubMenu, 2 = Second SubMenu...).
    let level = 0;

    // The sidebar will be "ready" only after it is fully open.
    useEffect(() => {
        let timer;
        if (expanded === true)
            timer = setTimeout(() => {setReady(true)} , 300)
        else
            setReady(false)
        return () => {
            clearTimeout(timer);
        };
    }, [expanded]);

    // Every time the url changes, the current page is set as active
    useEffect(() => {
       setActive( path )
    }, [path]);


    return (
        <div className={`custom-sidebar ${expanded && "expanded"}`} ref={innerRef}
             onMouseLeave={() => {
                let timer = setTimeout(() => {setExpanded(false)} , 300)
                return () => {
                    clearTimeout(timer);
                };
             }}
        >

        {
            menuItems.map((item, index) => (
                <Restricted
                    key={item.name + "-restriction"}
                    permission={item.permission}>
                    <MenuItem {...{item, expanded, setExpanded, active, setActive, ready, level}}
                              path = {""}
                              key = {item.name + "-key"} />
                </Restricted>
                )
            )
        }

        </div>
    );
};
