import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import {Button} from "react-bootstrap";

const  CustomButton = ({ icon, path, text, className, action, param, collapse, disable, style, type }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if(action){
            if(param){
                action(param);
            }else{
                action();
            }
        }
        if(path){
            navigate(path);
        }
    };

    return (
        <Button
            className={className}
            onClick={handleClick}
            data-card-widget={collapse}
            disabled={disable}
            type={type}
            style={style} >
            <IconContext.Provider value={{ size: "1.2em", className: "global-class-name margin-icon" }}>
                {icon}{text}
            </IconContext.Provider>
        </Button>
    );
}

export default CustomButton;