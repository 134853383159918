import ReactQuill from "react-quill";
import {Accordion, Badge} from "react-bootstrap";
import {FaCheckCircle} from "react-icons/fa";
import {FaCircleXmark} from "react-icons/fa6";

const parseLevelBadgeColor = (level) => {
    switch(level) {
        case "JUNIOR":
            return "success";
        case "MIDDLE":
            return "warning";
        case "SENIOR":
            return "danger";
        default:
            return "primary";
    }
}

const HiringQuestionAnswersView = ( { question, index } ) => {
    return(
        <>
            <div key={`question-title-${index + 1}`} className={"row"} style={{marginBottom: "1%"}}>
                <div className={"col-10"}>{`${index + 1} - ${question.questionTitle}`}</div>
                <div className={"col-2"} style={{fontSize: "0.95rem", }}>
                    Livello Domanda:
                    <Badge style={{marginLeft: "3%"}} bg={parseLevelBadgeColor(question.questionLevel)}>
                        { question.questionLevel }
                    </Badge></div>
            </div>
            <div
                key={`question-quill-mb-${index + 1}`}
                className={"mb-3"}>
                <ReactQuill
                    key={`question-quill-${index + 1}`}
                    value={question.question}
                    readOnly={true}
                    modules={{toolbar: false}}
                />
            </div>
            <Accordion
                className={"mb-4"}
                key={`question-answers-accordion-${index + 1}`}
            >
                <Accordion.Item
                    key={`question-answers-accordion-item-${index + 1}`}
                    eventKey="0"
                >
                    <Accordion.Header
                        key={`question-answers-accordion-header-${index + 1}`}>
                        Risposte
                    </Accordion.Header>
                    <Accordion.Body key={`question-answers-accordion-body-${index + 1}`}>
                        {
                            question.answers.map((answer, answerIndex) => {
                                return (
                                    <div key={`question-${index}-answer-${answerIndex + 1}`}>
                                        <Badge
                                            key={`question-${index}-answer-${answerIndex + 1}-badge`}
                                            className={"mr-2"}
                                            bg={answer.isRight ? "success" : "danger"}>
                                            {
                                                answer.isRight ?
                                                    <FaCheckCircle
                                                        key={`question-${index}-answer-${answerIndex + 1}-right`}/>
                                                    :
                                                    <FaCircleXmark
                                                        key={`question-${index}-answer-${answerIndex + 1}-wrong`}/>
                                            }
                                        </Badge>
                                        {answer.answer}
                                    </div>
                                )
                            })
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}

export default HiringQuestionAnswersView;