import {Routes, Route} from 'react-router-dom';
import UsersPage from "../pages/users/UsersPage";
import CreateUserPage from "../pages/users/createUser/CreateUserPage";
import EditUserPage from "../pages/users/editUser/EditUserPage";


const UsersRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<UsersPage />} />
                <Route path="/create" element={<CreateUserPage />} />
                <Route path="/:userId/edit" element={<EditUserPage />} />
            </Routes>
        </>
    );
}

export default UsersRoutes;
