import {Alert, Card, Spinner} from "react-bootstrap";
import {Doughnut} from "react-chartjs-2";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import axios from "axios";
import {properties} from "../../../properties";
import {useEffect, useState} from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

const DatabaseDonutGraph = () => {

    const [categoriesLoaded, setCategoriesLoaded] = useState({categories:[]});
    const [isLoading, setIsLoading] = useState(true);

    const loadCategories = () => {
        setIsLoading(true);
        axios.get(properties.basePath + "/categories/all.php")
            .then(res => {
                const response = res.data;
                setCategoriesLoaded({...categoriesLoaded, ...response});
                setIsLoading(false);
            })
    }

    useEffect(() => {loadCategories()}, []);

    const generateColor = (colorNum) => {
        const hue = colorNum * 137.508;
        return "hsl(" + (hue) + ",100%,50%)";
    }

    const generateDatabaseData = () => {
        let result = {
            labels: [],
            datasets: [
                {
                    label: '# Domande',
                    data: [],
                    backgroundColor: [],
                    borderColor: [],
                    borderWidth: 1,
                }
            ]
        }

        let x = 1;
        for(let category of categoriesLoaded.categories) {
            result.labels.push(category.name);
            result.datasets[0].data.push(category.questions);
            result.datasets[0].backgroundColor.push(generateColor(x));
            x++;
        }

        return result;
    }

    return (
        <>
            <Card className={"card-primary"}>
                <Card.Header>
                    <Card.Title>
                        Database
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text className={"text-center"} as={"div"}>
                        {isLoading ? (
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                            ) :
                            categoriesLoaded.categories.length === 0 ? (
                                <Alert variant="danger">
                                    <span>
                                        Nessuna categoria esistente
                                    </span>
                                </Alert>
                            ) : (
                                <Doughnut
                                    style={{height: "60vh"}}
                                    data={generateDatabaseData()}
                                    redraw={true}
                                    options={{ maintainAspectRatio: false }}
                                />
                            )}
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default DatabaseDonutGraph;