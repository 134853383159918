import loginReducer from "./reducers/login-reducer";
import uiReducer from "./reducers/ui-reducer";
import hiringReducer from "./reducers/hiring-reducer";
import {combineReducers} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import notificationReducer from "./reducers/notification-reducer";


const localPersistConfig = {
    key: 'local',
    storage,
}

const sessionPersistConfig = {
    key: 'sess',
    storage: storageSession,
}

export const reducers = persistReducer(sessionPersistConfig, combineReducers({
    auth: loginReducer,
    ui: uiReducer,
    hiring: hiringReducer,
    notification: notificationReducer
}));