import {Alert, Badge, Card, Modal, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import {FaFolderOpen, FaPencil, FaTrashCan} from "react-icons/fa6";
import CustomButton from "../../ui/CustomButton";
import TablePlaceholder from "./TablePlaceholder";
import {store} from "../../../store/store";
import {FaMinus, FaPlus, FaReply, FaTasks, FaTrash} from "react-icons/fa";
import DataPagination from "../../ui/DataPagination";
import QuestionSearchFilter from "./SearchFilter/QuestionSearchFilter";
import moment from "moment";
import Restricted from "../../ui/Restricted";

const DatabaseCategoryQuestions = ({ category, data, handleQuestionDelete, isLoading }) => {

    const [dataToShow, setDataToShow] = useState([]);
    const [dataLoaded, setDataLoaded] = useState({questions:[]});

    const [questionToDelete, setQuestionToDelete] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [iconCollapse,setIconCollapse]  = useState(false);
    const [isTransitioning,setIsTransitioning]  = useState(false);

    const handleModalClose = () => {
        setQuestionToDelete({...questionToDelete, ...{}});
        setShowDeleteModal(false);
    }

    const handleModalShow = (category) => {
        setQuestionToDelete({...questionToDelete, ...category});
        setShowDeleteModal(true);
    }
    const parseLevelBadgeColor = (level) => {
        switch(level) {
            case "JUNIOR":
                return "success";
            case "MIDDLE":
                return "warning";
            case "SENIOR":
                return "danger";
            default:
                return "primary";
        }
    }

    const parseQuestionTypeIcon = (questionType) => {
        switch(questionType) {
            case "SINGLE":
                return <FaReply/>;
            case "MULTIPLE":
                return <FaTasks/>;
        }
    }

    useEffect(()=> {
        setDataLoaded({...dataLoaded, ...data});
    }, [data.questions]);

    const handleClick = () => {
        if (!isTransitioning) {
            setTimeout(()=>{
                setIsTransitioning(true);
                setIconCollapse(!iconCollapse);
            },1)
            setTimeout(() => {
                setIsTransitioning(false);
            }, 400);
        }
    };

    return (
        <>
            <Card className={"card-primary"}>
                <Card.Header>
                    <Card.Title>Cerca Domande</Card.Title>
                    <div className={"card-tools"}>
                        <CustomButton
                            icon={!iconCollapse ? <FaMinus /> : <FaPlus />}
                            className={`btn-info btn-sm mr-2 ${isTransitioning ? 'disabled' : ''}`}
                            collapse={"collapse"}
                            action={handleClick}
                        />
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card.Text as={"div"}>
                        <QuestionSearchFilter
                            setDataLoaded={setDataLoaded}
                            originalData={data}
                        />
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card className={"card-primary"}>
                <Card.Header>
                    <Card.Title>Domande</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text as={"div"}>
                        { isLoading ?
                            <TablePlaceholder/>
                            :
                            dataToShow.length === 0 ? (
                                <Alert variant="danger">
                                    <span>Nessuna domanda esistente</span>
                                </Alert>
                            ) :
                            (
                                <Table className={"table-striped"} >
                                    <thead>
                                        <tr>
                                            <th
                                                key={"th1" + new Date().toTimeString()}
                                                style={{width:"30%"}}>
                                                Domanda
                                            </th>
                                            <th key={"th2" + new Date().toTimeString()} style={{textAlign: "center"}}>
                                                Livello
                                            </th>
                                            <th key={"th3" + new Date().toTimeString()} style={{textAlign: "center"}}>
                                                Tipo
                                            </th>
                                            <th key={"th4" + new Date().toTimeString()} style={{textAlign: "center"}}>
                                                Autore
                                            </th>
                                            <th key={"th5" + new Date().toTimeString()} style={{textAlign: "center"}}>
                                                Data ultima modifica
                                            </th>
                                            <th key={"th6" + new Date().toTimeString()}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dataToShow.map((q) => {
                                            return (
                                                <tr key={"tr - " + q.id}>
                                                    <td key={"td1 - " + q.id}>
                                                        {
                                                            q.questionTitle.length > 80 ? q.questionTitle.slice(0,80) + "..." : q.questionTitle
                                                        }
                                                    </td>
                                                    <td key={"td2 - " + q.id} className={"text-center"}>
                                                        <Badge bg={parseLevelBadgeColor(q.questionLevel)}>
                                                            { q.questionLevel }
                                                        </Badge>
                                                    </td>
                                                    <td key={"td3 - " + q.id} style={{textAlign: "center"}}>
                                                        {parseQuestionTypeIcon(q.questionType)}
                                                    </td>
                                                    <td key={"td4 - " + q.id} className={"text-center"}>
                                                        {q.author}
                                                    </td>
                                                    <td key={"td5 - " + q.id} className={"text-center"}>
                                                        {moment.utc(q.lastUpdateDate).local().format('L LT')}
                                                    </td>
                                                    <td key={"td6 - " + q.id} className={"text-right"}>
                                                        <CustomButton
                                                            key={"button1 - " + q.id}
                                                            icon={<FaFolderOpen/>}
                                                            className={"btn-primary btn-sm mr-2"}
                                                            path={`/database/${category}/${q.id}`}
                                                        />
                                                        <Restricted permission={"editQuestion"}>
                                                            <CustomButton
                                                                key={"button2 - " + q.id}
                                                                icon={<FaPencil/>}
                                                                className={"btn-info btn-sm mr-2"}
                                                                path={`/database/${category}/${q.id}/edit`}
                                                            />
                                                        </Restricted>
                                                        <Restricted permission={"deleteQuestion"}>
                                                            <CustomButton
                                                                key={"button3 - " + q.id}
                                                                icon={<FaTrashCan/>}
                                                                className={"btn-danger btn-sm mr-2"}
                                                                action={() => handleModalShow(
                                                                    {
                                                                        id: q.id,
                                                                        questionTitle: q.questionTitle
                                                                    }
                                                                )}
                                                            />
                                                        </Restricted>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </Table>
                            )
                        }
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <DataPagination
                        dataLoaded={dataLoaded.questions}
                        dataToShow={dataToShow}
                        setDataToShow={setDataToShow}
                    />
                </Card.Footer>
            </Card>

            <Modal show={showDeleteModal} onHide={handleModalClose}>
                <Modal.Header closeButton className={(store.getState().ui.darkMode ? "dark-mode" : "")}>
                    <Modal.Title>Eliminare la domanda ?</Modal.Title>
                </Modal.Header>
                <Modal.Body className={(store.getState().ui.darkMode ? "dark-mode" : "")}>
                    <p>
                        Tutte le risposte all'interno della domanda verranno perse.
                    </p>
                </Modal.Body>
                <Modal.Footer className={(store.getState().ui.darkMode ? "dark-mode" : "")}>
                    <CustomButton
                        text={"Chiudi"}
                        key={"close-category-confirmation"}
                        className={"btn-secondary btn-md mr-2"}
                        action={handleModalClose}
                    />
                    <CustomButton
                        text={" Elimina"}
                        key={"delete-category-confirmation"}
                        icon={<FaTrash/>}
                        className={"btn-danger btn-md mr-2"}
                        action={
                            () => {
                                handleQuestionDelete(questionToDelete.id);
                                handleModalClose();
                            }
                        }
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DatabaseCategoryQuestions;