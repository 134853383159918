import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {LiaUserCircle} from 'react-icons/lia'
// import { RxHamburgerMenu } from "react-icons/rx";
import {IconContext} from "react-icons";
import React, {useCallback, useRef, useState} from "react";
import {TbLogout2} from "react-icons/tb";
import {Sidebar} from "../sidebar/Sidebar";
import {MdOutlineDarkMode, MdOutlineLightMode} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import ringmasterLogo from "../../image/ringmaster.png";
import '../../styles/header.scss'
import '../../styles/hamburger.scss'
import {Image} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {toggleDarkMode} from "../../store/reducers/ui-reducer";
import {executeLogout} from "../../store/reducers/login-reducer";
import {store} from "../../store/store";
import {FaKey} from "react-icons/fa";
import {LinkContainer} from "react-router-bootstrap";

const Header = (callback, deps) => {

    const loggedUserId = useSelector((state) => state.auth.userId);

    const handleLogout = () => {
        dispatch(executeLogout())
        navigate('/login');
    }

    const handleDarkMode = () => {
        dispatch(toggleDarkMode())
        setDarkMode(store.getState().ui.darkMode)
    }


    // const hamburgerIcon = (
    //     <IconContext.Provider value={{ color: "white", size:"1em" ,className: "global-class-name" }}>
    //         <div className={"header-icons"}>
    //             <RxHamburgerMenu />
    //         </div>
    //     </IconContext.Provider>
    // );

    const navigate = useNavigate();
    const desktop = useSelector((state) => state.ui.desktop)
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false)
    const [darkMode, setDarkMode] = useState(store.getState().ui.darkMode)
    const username = store.getState().auth.username;
    const sidebarRef = useRef(null);
    const navbarRef = useRef(null);

    const handleInsideClick = () => {
        console.log(!expanded)
        if (!expanded) {
            window.addEventListener('click', handleOutsideClick);
            document.querySelector(".c-hamburger").classList.add("is-active");
        }
        else {
            window.removeEventListener('click', handleOutsideClick);
            document.querySelector(".c-hamburger").classList.remove("is-active");
        }
        setExpanded(!expanded);
    }

    const handleOutsideClick = useCallback((e) => {
        if (!expanded  && !desktop) {
            if (!navbarRef.current.contains(e.target)) {
                setExpanded(false);
                window.removeEventListener('click', handleOutsideClick)
                document.querySelector(".c-hamburger").classList.remove("is-active");
            }
        }
    }, [desktop])

    const userIcon = (
        <div className={"row"}>
            <div className={"col-auto"}>
                <IconContext.Provider value={{ color: "white", size:"2em" ,className: "global-class-name" }}>
                    <div className={"header-icons"}>
                        <LiaUserCircle />
                    </div>
                </IconContext.Provider>
            </div>
            {desktop &&
                <div className={"col-auto"}>
                    <Navbar.Text >
                        {username}
                    </Navbar.Text>
                </div>
            }
        </div>
    );

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary height"  bg="dark" data-bs-theme="dark"  sticky={"top"} >
                <Container className={"justify-content-between"} style={{maxWidth: "97%", display:"flex" , height:"100%", alignItems:"flex-start", marginLeft:"0", marginRight:"0"}}>
                    {!desktop && <div className={"col-auto"} ref={navbarRef}>
                        <button className="c-hamburger c-hamburger--htx" onClick={() => {handleInsideClick()}}>
                            <span></span>
                        </button>
                    </div>}
                    <div className={"col-auto"}>
                        <div>
                            <Image
                                className={"logo"}
                                src={ringmasterLogo} fluid
                                onClick={() => navigate(`/Dashboard`)}
                            >
                            </Image>
                        </div>
                    </div>

                    <div className={"col-auto"}>
                        <NavDropdown title={userIcon} id="basic-nav-dropdown">
                            <LinkContainer to={`/users/${loggedUserId}/edit`}>
                                <NavDropdown.Item>
                                    <IconContext.Provider value={{ color: "#343a40", size:"1em" ,className: "global-class-name" }}>
                                        <div className={"dropdown-icons"}>
                                            <FaKey/>
                                        </div>
                                    </IconContext.Provider>
                                    <div style={{paddingLeft : "1vw"}}>
                                        Cambia Password
                                    </div>
                                </NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Item onClick={handleDarkMode}>
                                <IconContext.Provider value={{ color: "#343a40", size:"1em" ,className: "global-class-name" }}>
                                    <div className={"dropdown-icons"}>
                                        {!darkMode ? <MdOutlineDarkMode /> : <MdOutlineLightMode/>}
                                    </div>
                                </IconContext.Provider>
                                <div style={{paddingLeft : "1vw"}}>
                                    {!darkMode ? "Dark Mode" : "Light Mode"}
                                </div>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item  onClick={handleLogout}>
                                <IconContext.Provider value={{ color: "red", size:"1em" ,className: "global-class-name logout" }}>
                                    <div className={"dropdown-icons"}>
                                        <TbLogout2 />
                                    </div>
                                </IconContext.Provider>
                                <div style={{paddingLeft : "1vw"}}>
                                    Logout
                                </div>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>

                </Container>
            </Navbar>

            {expanded && <Sidebar expandedStart={expanded} innerRef={sidebarRef} />}

        </>

    );
};
export default Header;

