import {Link} from "react-router-dom";
import {FaArrowCircleRight} from "react-icons/fa";

const SmallBox = (props) => {
    const parseColor = (color) => {
        if(color === 'yellow') {
            return "bg-warning"
        }
        else if (color === 'green') {
            return 'bg-success';
        }
        else if (color === 'blue') {
            return 'bg-info';
        }
    }

    return(
        <>
            <div className={"small-box " + parseColor(props.color)}>
                <div className="inner">
                    <h3>{props.number}</h3>
                    <p>{props.text}</p>
                </div>
                <div className="icon">
                    {props.icon}
                </div>
                {props.link ?
                    <Link to={props.link} className="small-box-footer" >
                        Visualizza
                        <FaArrowCircleRight className={"ml-2"}/>
                    </Link> : null}
            </div>
        </>
    );
}

export default SmallBox;