import {Formik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import CustomButton from "../../../ui/CustomButton";
import {toast} from "react-toastify";
import * as yup from "yup";

function QuestionSearchFilter({ setDataLoaded, originalData }) {

    const schema = yup.object().shape({
        name: yup.string()
            .min(1, 'Richiesto almeno un carattere')
    });

    const filterCategories = (values) => {
        let categories = { categories: originalData.categories };
        for (const [key, value] of Object.entries(values)) {
            categories = {
                ...{
                    categories: categories.categories.filter(category =>
                        String(category[key]).toLowerCase()
                            .includes(value.toLowerCase())
                    )
                }
            };
        }
        if (categories.categories.length === 0) {
            toast.error("Nessuna categoria trovata", {autoClose: 2000});
        }
        setDataLoaded(categories);
    }

    return (
        <Formik
            validationSchema={ schema }
            initialValues= {
                {
                    name: '',
                }
            }
            onSubmit= { (values) => filterCategories(values) }
            onReset={ () => setDataLoaded(originalData) }
        >
            {
                ({ handleSubmit, handleReset, handleChange, touched, errors, values }) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit} className={"ml-4 mr-4"}>
                            <Row>
                                <Col xs={"auto"} md={12}>
                                    <Form.Group controlId="validationCategoryName">
                                        <Form.Label>
                                            Nome Categoria
                                        </Form.Label>
                                        <Form.Control
                                            name={"name"}
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={touched.name && errors.name}
                                        />
                                        <Form.Control.Feedback type={"invalid"}>
                                            { errors.name }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className={"text-right mt-3"}>
                                <CustomButton
                                    text={" Cerca"}
                                    type={"button"}
                                    className={"btn-success btn-md ml-2"}
                                    action={handleSubmit}
                                />
                                <CustomButton
                                    text={"Cancella"}
                                    type={"button"}
                                    className={"btn-error btn-md ml-2"}
                                    action={handleReset}
                                />
                            </div>
                        </Form>
                    </>
                )
            }
        </Formik>
    );
}

export default QuestionSearchFilter;