import { createSlice } from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

const initialState = {

}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        showSuccessNotification: (state, {payload}) => {
            toast.success(payload.message, {autoClose: 2000});
        },
        showErrorNotification: (state, {payload}) => {
            toast.error(payload.message, {autoClose: 2000});
        }
    }
});

export const { showSuccessNotification, showErrorNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
