import {useSelector} from "react-redux";
import {useEffect} from "react";

const Restricted = ({permission, children}) => {

    const userPermissions = useSelector((state) => state.auth.permissions);

    return (
        (userPermissions.includes(permission)) && children
    );

}

export default Restricted;