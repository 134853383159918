import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import PageSection from "../../ui/PageSection";
import DatabaseCategoryQuestions from "./DatabaseCategoryQuestions";
import axios from "axios";
import {properties} from "../../../properties";
import {useEffect, useState} from "react";
import {LinkContainer} from "react-router-bootstrap";
import {notifySuccess} from "../../services/notification-service";
import CustomButton from "../../ui/CustomButton";
import {FaPlus} from "react-icons/fa6";
import Page from "../../ui/Page";
import Restricted from "../../ui/Restricted";

const DatabaseCategoryPage = () => {

    let { selectedCategory } = useParams();

    const [data, setData] = useState({questions:[]});
    const [isLoading, setIsLoading] = useState(true);


    const loadQuestions = () => {
        setIsLoading(true);
        axios.get(properties.basePath + "/categories/questions.php?category=" + selectedCategory)
            .then(res => {
                const response = res.data;
                setData({...data, ...response});
                setIsLoading(false);
            })
    }

    const handleQuestionDelete = (questionId) => {
        axios.delete(properties.basePath + "/questions/delete.php?questionId=" + questionId)
            .then(res => {
                notifySuccess("Domanda eliminata con successo!");
                loadQuestions();
            })
    }

    useEffect(() => {loadQuestions()}, []);

    return (
        <Page>
            <PageSection title={"Categoria - " + selectedCategory}/>
            <Container fluid>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <LinkContainer to={"/database"}>
                                <Breadcrumb.Item>Database</Breadcrumb.Item>
                            </LinkContainer>
                            <Breadcrumb.Item active>{selectedCategory}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Restricted permission={"createQuestion"}>
                    <Row>
                        <Col className={"text-right"}>
                            <CustomButton
                                icon={<FaPlus/>}
                                text={" Crea domanda"}
                                path={`/database/${selectedCategory}/create`}
                                className={"btn-success btn-md mb-2"}
                            />
                        </Col>
                    </Row>
                </Restricted>
                <Row>
                    <Col>
                        <DatabaseCategoryQuestions
                            data={data}
                            category={selectedCategory}
                            handleQuestionDelete={handleQuestionDelete}
                            isLoading = {isLoading}
                        />
                    </Col>
                </Row>
            </Container>
        </Page>
    );

}

export default DatabaseCategoryPage;