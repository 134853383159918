import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import DatabaseDataLoaded from "./DatabaseDataLoaded";
import axios from "axios";
import {properties} from "../../../properties";
import {axiosInstance} from "../../services/interceptor/axiosInstance";
import "../../../styles/pages.scss";
import Page from "../../ui/Page";
import {notifySuccess} from "../../services/notification-service";

const DatabasePage = () => {

    const [categories, setCategories] = useState({categories:[]});
    const [isLoading, setIsLoading] = useState(true);


    const loadCategories = () => {
        setIsLoading(true);
        axios.get(properties.basePath + "/categories/all.php")
            .then(res => {
                const response = res.data;
                setCategories({...categories, ...response});
                setIsLoading(false);
            })
    }

    const handleCreateCategory = (categoryName) => {
        const formData = new FormData();
        formData.append('name', categoryName);

        axiosInstance({
            method: "post",
            url: "/categories/create.php",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })
            .then(function (response) {
                //handle success
                notifySuccess("Categoria creata con successo!");
                loadCategories();
            })
            .catch(function (response) {
                //handle error
            });
    }

    const handleDeleteCategory = (categoryId) => {
        axiosInstance({
            method: "delete",
            url: "/categories/delete.php?categoryId=" + categoryId,
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })
            .then(res => {
                loadCategories();
            })
            .catch(function (response) {
                //handle error
            });
    }

    useEffect(() => {
        loadCategories()
        },
        []);

    return (
        <Page>
            <Container fluid>
                <Row>
                    <Col>
                        <h2 className={"mt-2 mb-4"}>Database</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item href="#" active>Database</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DatabaseDataLoaded
                            data={categories}
                            handleCreateCategory={handleCreateCategory}
                            handleDeleteCategory={handleDeleteCategory}
                            isLoading = {isLoading}
                        />
                    </Col>
                </Row>
            </Container>
        </Page>
    );

}

export default DatabasePage;