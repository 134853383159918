import {Badge, Card, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import {properties} from "../../../properties";
import {FaExchangeAlt, FaMinus, FaPlus, FaSyncAlt, FaTrashAlt} from "react-icons/fa";
import CustomButton from "../../ui/CustomButton";
import {useDispatch, useSelector} from "react-redux";
import {addData, addQuestion, refreshQuestion, removeQuestion} from '../../../store/reducers/hiring-reducer';
import TablePlaceholder from "../database/TablePlaceholder";
import {notifySuccess} from "../../services/notification-service";


const RandCategoryQuestionCard = ({ category, remove }) => {

    const MAX_QUESTIONS = properties.hiring.categories.maxQuestions;

    let questionsLoaded = useSelector(state => state.hiring);

    let alreadyLoadedCategory = questionsLoaded.filter(item => item.category.id === category.id);
    let alreadyLoadedQuestions = questionsLoaded.find(element => element.category.id === category.id);

    const [loadedQuestions, setLoadedQuestions] = useState({questions:[]});
    const [questions, setQuestions] = useState({questions:[]});
    const [iconCollapse,setIconCollapse]  = useState(false);
    const [isTransitioning,setIsTransitioning]  = useState(false);
    const [questionsToRemove, setQuestionsToRemove] = useState([]);

    const dispatch = useDispatch();

    const handleClick = () => {
        if (!isTransitioning) {
            setTimeout(()=>{
                setIsTransitioning(true);
                setIconCollapse(!iconCollapse);
            },1)
            setTimeout(() => {
                setIsTransitioning(false);
            }, 400);
        }
    };

    const loadQuestions = () => {
            axios.get(properties.basePath + "/questions/getByCategoryId.php?categoryId=" + category.id)
                .then(res => {
                    const response = res.data;
                    // Filtra gli oggetti presenti in alreadyLoadedQuestions ma non in response (domande eliminate dal db)
                    let questionsDeleted = alreadyLoadedQuestions?.questions.filter((item) => !response.questions.some((subItem) => subItem.id === item.id)) || [];
                    if(questionsDeleted.length !== 0) {
                        questionsDeleted.forEach(question => {
                            let removeQuestion = questionsToRemove.map(item => {
                                if(item[0].id !== question.id)
                                    return item
                            })
                            if(removeQuestion.length === 0){
                                setQuestionsToRemove(prevState => [...prevState, questionsDeleted])
                            }
                        })
                    }
                    setLoadedQuestions({...loadedQuestions, ...response});
                })
                .catch(error => {
                    console.log("Error:", error);
                    if (error.response && error.response.status === 400) {
                        // Handle Bad Request error here
                        console.log("Bad Request: Category ID does not exist");
                    }
                });
            if(alreadyLoadedCategory.length > 0){
                setQuestions({...questions, ...alreadyLoadedCategory.questions});
            }
    }

    const generateRandomNumber = (maxNumber) => {
        return Math.floor(Math.random() * (maxNumber));
    };

    const extractRandomQuestion = () => {
        return loadedQuestions.questions[generateRandomNumber(loadedQuestions.questions.length)];
    }

    const extractRandomQuestionWithoutDuplicate = () => {
        let found = false;
        let extractedItem = extractRandomQuestion();

        while(!found) {
            if (questions.questions.filter((question) => question.id === extractedItem.id).length === 0) {
                found = true;
            } else {
                extractedItem = extractRandomQuestion();
            }
        }

        return extractedItem;
    }

    const generateRandomQuestions = () => {
        const result = {questions: []};
        for(let i = 0; i < MAX_QUESTIONS; i++) {
            const extractedItem = extractRandomQuestion();
            if (!result.questions.includes(extractedItem)) {
                result.questions.push(extractedItem);
            } else {
                i--;
            }
        }
        setQuestions({...questions, ...result});
        dispatch(addData({
            category:{ id:category.id, name: category.name },
            questions:{...questions, ...result}.questions
        }));
        if(questions.questions.length === 0) {
            notifySuccess(`Categoria ${category.name} caricata!`);
        }
    }

    const setCategoryQuestions = () => {if (alreadyLoadedCategory.length > 0) {
        if (alreadyLoadedCategory[0].questions.length <= 0) {
            generateRandomQuestions();
        } else {
            let updatedQuestions = alreadyLoadedCategory[0].questions.slice(); // Copia dell'array di domande
            if(questionsToRemove.length !== 0){
                questionsToRemove.forEach(question => {
                    updatedQuestions = updatedQuestions.filter(item => item.id !== question[0].id);
                    dispatch(removeQuestion({
                        categoryId: category.id,
                        questionId: question[0].id
                    }));
                });
            }
            let result = {
                questions: updatedQuestions
            };

            setQuestions({ ...questions, ...result });
        }
    }
 else if (loadedQuestions.questions.length > 0 && loadedQuestions.questions.length <= MAX_QUESTIONS) {
            setQuestions({...questions, ...loadedQuestions});
            dispatch(addData({
                category:{ id:category.id, name: category.name },
                questions:{...questions, ...loadedQuestions}.questions
            }))
        } else {
            if(loadedQuestions.questions.length > MAX_QUESTIONS) {
                generateRandomQuestions();
            }
        }
    }

    const handleQuestionDelete = (categoryId, questionId) => {
        const result = {questions: []};
        result.questions = questions.questions.filter(question => question.id !== questionId);
        setQuestions((oldState) => ({ ...oldState, ...result }));
        dispatch(removeQuestion({
            categoryId: categoryId,
            questionId: questionId
        }));
    }

    const handleQuestionRefresh = (index, categoryId) => {
        const updatedQuestions = [...questions.questions]; // Creo una copia dell'array delle domande
        const newQuestion = extractRandomQuestionWithoutDuplicate();
        updatedQuestions[index] = newQuestion; // Aggiorno l'elemento dell'array con la nuova domanda

        setQuestions((prevState) => ({
            ...prevState,
            questions: updatedQuestions // Aggiorno lo stato con la nuova copia dell'array delle domande
        }));

        dispatch(refreshQuestion({
            index: index,
            categoryId: categoryId,
            newQuestion: newQuestion
        }));
    }

    const handleAddQuestionRandomly = () => {
        const result = {questions: []};
        result.questions = questions.questions;
        const newQuestion = extractRandomQuestionWithoutDuplicate();
        dispatch(addQuestion({
            categoryId:category.id,
            newQuestion: newQuestion
        }))
        result.questions.push(newQuestion);
        setQuestions((oldState) => ({ ...oldState, ...result }));
    }

    const parseLevelBadgeColor = (level) => {
        switch(level) {
            case "JUNIOR":
                return "success";
            case "MIDDLE":
                return "warning";
            case "SENIOR":
                return "danger";
            default:
                return "primary";
        }
    }

    useEffect(
        () => {
            loadQuestions();
        },
        []
    );

    useEffect(() => {
        setCategoryQuestions();
    }, [loadedQuestions])


    return(
        <>
            <Card
                key={"card-" + category.id + "-questions"}
                className={"card-primary"}>
                <Card.Header
                    key={"card-header" + category.id + "-questions"}>
                    <Card.Title
                        key={"card-title" + category.id + "-questions"}>
                        { category.name }
                    </Card.Title>
                    <div className="card-tools">
                        {
                            loadedQuestions.questions.length > MAX_QUESTIONS &&
                                <CustomButton
                                    icon={<FaSyncAlt />}
                                    className={"btn-info btn-sm mr-2"}
                                    action={generateRandomQuestions}
                                />
                        }
                        <CustomButton
                            icon={!iconCollapse ? <FaMinus /> : <FaPlus />}
                            className={`btn-info btn-sm mr-2 ${isTransitioning ? 'disabled' : ''}`}
                            collapse={"collapse"}
                            action={handleClick}
                        />
                        <CustomButton
                            icon={<FaTrashAlt/>}
                            className={`btn-danger btn-sm mr-2`}
                            action={remove}
                            param={{category:{ id:category.id, name: category.name }}}
                        />
                    </div>
                </Card.Header>
                <Card.Body
                    key={"card-body" + category.id + "-questions"}>
                    <Card.Text
                        key={"card-text" + category.id + "-questions"}
                        as={"div"}>
                        <Table key={category.id + "questions-table"}>
                            <tbody key={category.id + "questions-table-tbody"}>
                            {
                                questions.questions.length > 0 ? (
                                    questions.questions.map( (q, index) => (
                                            <tr key={q.id + "-question" + "-" + index}>
                                                <td>{q.questionTitle}</td>
                                                <td className={"text-right"}>
                                                    <Badge bg={parseLevelBadgeColor(q.questionLevel)}>
                                                        { q.questionLevel }
                                                    </Badge>
                                                </td>
                                                <td className={"text-right"}>
                                                    {
                                                        loadedQuestions.questions.length > questions.questions.length &&
                                                            <CustomButton
                                                                icon={<FaExchangeAlt />}
                                                                className={`btn-primary btn-sm mr-2`}
                                                                action={() => handleQuestionRefresh(index, category.id)}
                                                            />
                                                    }
                                                    {
                                                        questions.questions.length > 1 &&
                                                            <CustomButton
                                                                icon={<FaTrashAlt/>}
                                                                className={`btn-danger btn-sm mr-2`}
                                                                action={() => handleQuestionDelete(category.id, q.id)}
                                                            />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="2"><TablePlaceholder /></td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>
                        {
                            (loadedQuestions.questions.length > questions.questions.length) && questions.questions.length < MAX_QUESTIONS &&
                                <CustomButton
                                    icon={<FaPlus />}
                                    className={`btn-success btn-sm mr-2`}
                                    action={handleAddQuestionRandomly}
                                />
                        }
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default RandCategoryQuestionCard;