import {FaClock, FaExternalLinkAlt, FaPlus, FaTrash} from "react-icons/fa";
import CustomButton from "../../../ui/CustomButton";
import {Card} from "react-bootstrap";
import moment from "moment";
import "moment/locale/it";

const UserActivity = ({ activity }) => {

    const generateActivityMessage = () => {
        switch(activity.activityType) {
            case 'DELETE':
                if(activity.questionId === null) {
                    return "Ha eliminato la categoria " + activity.category;
                } else {
                    return "Ha eliminato nella categoria " + activity.category + " la domanda: " + activity.questionId;
                }
            case 'UPDATE':
                if(activity.questionId === null) {
                    return "Ha aggiornato la categoria " + activity.category;
                } else {
                    return "Ha aggiornato nella categoria " + activity.category + " la domanda: " + activity.questionId;
                }
            case 'CREATE':
                if(activity.questionId === null) {
                    return "Ha creato la categoria " + activity.category;
                } else {
                    return "Ha creato una nuova domanda nella categoria " + activity.category;
                }
        }
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title style={{width: "100%"}}>
                        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                            <div style={{textAlign:"left", width: "50%"}}>
                                <h5>{activity.username}</h5>
                            </div>
                            <div style={{textAlign:"right", width: "50%"}}>
                                <FaClock /> {moment(activity.activityDate).locale("it").fromNow()}
                            </div>
                        </div>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {generateActivityMessage()}
                </Card.Body>
                <Card.Footer>
                    { activity.activityType !== "DELETE" &&
                        <CustomButton
                            text={" Visualizza"}
                            icon={<FaExternalLinkAlt />}
                            className={"btn-primary btn-sm mr-1"}
                            path={
                                activity.questionId === null ? `/database/${activity.category}` : `/database/${activity.category}/${activity.questionId}`
                            }
                        />
                    }
                </Card.Footer>
            </Card>
        </>
    );
}

export default UserActivity;