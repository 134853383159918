import {Form, Image} from "react-bootstrap";
import ringmasterCover from "../../../image/ringmaster_cover.jpg";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import axios from "axios";
import {properties} from "../../../properties";
import {notifyError, notifySuccess} from "../../services/notification-service";
import {executeLogin, setAuthentication} from "../../../store/reducers/login-reducer";
import {Formik} from "formik";
import * as yup from "yup";
import CustomButton from "../../ui/CustomButton";
import {IoMdLogIn} from "react-icons/io";

const LoginForm = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogin = (username, password) => {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        axios(
            {
                method: "post",
                url: properties.basePath + "/login.php",
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }
        )
            .then(function (response) {
                //handle success
                notifySuccess("Login effettuata!");
                dispatch(executeLogin(response.data.username))
                dispatch(setAuthentication(response.data))
                navigate("/");
            })
            .catch(function (response) {
                //handle error
                notifyError("Username o password non valida");
            });
    }

    const schema = yup.object().shape({
        username: yup.string()
            .required('Campo obbligatorio'),
        password: yup.string()
            .required('Campo obbligatorio')
    });

    return (

        <Formik
            validationSchema={ schema }
            onSubmit={(values) => {handleLogin(values.username, values.password)}}
            initialValues={
                {
                    username: '',
                    password: '',
                }
            }
        >
            {
                ({ handleSubmit, handleChange, touched, errors, values }) => (
                    <Form noValidate onSubmit={ handleSubmit }>
                        <Image
                            src={ringmasterCover} rounded fluid>
                        </Image>

                        <Form.Group controlId="usernameValidation">
                            <Form.Control
                                name={"username"}
                                className={"mt-4"}
                                type="text"
                                placeholder="Username"
                                value={values.username}
                                onChange={handleChange}
                                isInvalid={touched.username && errors.username}
                            />
                            <Form.Control.Feedback type={"invalid"}>
                                { errors.username }
                            </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group controlId="passwordValidation">
                            <Form.Control
                                name={"password"}
                                className={"mt-2"}
                                type="password"
                                placeholder="Password"
                                value={values.password}
                                onChange={handleChange}
                                isInvalid={touched.password && errors.password}
                            />
                            <Form.Control.Feedback type={"invalid"}>
                                { errors.password }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <CustomButton
                            type={"submit"}
                            style = {{width: "100%"}}
                            text={" Login"}
                            icon={<IoMdLogIn />}
                            className={"btn-info mt-4 mr-2"}
                        />
                    </Form>
                )
            }
        </Formik>
    );
}

export default LoginForm;