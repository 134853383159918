import {Col, Container, Placeholder, Row} from "react-bootstrap";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import SmallBox from "../../ui/SmallBox";
import {useEffect, useState} from "react";
import DatabaseDonutGraph from "../database/DatabaseDonutGraph";
import UsersLastActivities from "./activities/UsersLastActivities";
import {axiosInstance} from "../../services/interceptor/axiosInstance";
import "../../../styles/pages.scss";
import {FaList, FaQuestion, FaUserNinja} from "react-icons/fa";
import Page from "../../ui/Page";
import Restricted from "../../ui/Restricted";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardPage = () => {

    const [dashboardData, setDashboardData] = useState({lastActivities:[]});
    const [isLoading, setIsLoading] = useState(true);

    const loadDashboardData = () => {
        setIsLoading(true);
        axiosInstance.get("/dashboard.php")
            .then(res => {
                const response = res.data;
                setDashboardData({...dashboardData, ...response});
                setIsLoading(false);
            })
    }

    useEffect(() => {loadDashboardData()}, []);

    return (
            <Page>
            <Container fluid>
                <Row>
                    <Col>
                        <h2 className={"mt-2 mb-4"}>Dashboard</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SmallBox
                            color={"yellow"}
                            number={isLoading ?  <Placeholder xs={1}  animation="glow" /> :  dashboardData.categories ? dashboardData.categories : 0}
                            text={"Categorie"}
                            icon={<FaList />}
                            link={"/database"}
                        />
                    </Col>
                    <Col>
                        <SmallBox
                            color={"green"}
                            number={isLoading ?  <Placeholder xs={1}  animation="glow" /> : dashboardData.questions ? dashboardData.questions : 0}
                            text={"Domande"}
                            icon={<FaQuestion />}
                            link={"/database"}
                        />
                    </Col>
                    <Restricted permission={"viewUsersPage"}>
                        <Col>
                            <SmallBox
                                color={"blue"}
                                number={isLoading ?  <Placeholder xs={1}  animation="glow" /> : dashboardData.users ? dashboardData.users : 0}
                                text={"Utenti"}
                                icon={<FaUserNinja />}
                                link={"/users"}
                            />
                        </Col>
                    </Restricted>
                </Row>
                <Row>
                    <Col md={4}>
                        <DatabaseDonutGraph data={dashboardData} />
                    </Col>
                    <Col md={8}>
                        <UsersLastActivities
                            activities={dashboardData.lastActivities} isLoading = {isLoading}/>
                    </Col>
                </Row>
            </Container>
        </Page>
    );

}

export default DashboardPage;