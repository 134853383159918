import {Formik} from "formik";
import {Col, Form, Row} from "react-bootstrap";
import CustomButton from "../../../ui/CustomButton";
import {toast} from "react-toastify";
import * as yup from "yup";

function QuestionSearchFilter({ setDataLoaded, originalData }) {

    const schema = yup.object().shape({
        questionTitle: yup.string()
            .min(1, 'Richiesto almeno un carattere'),
        author: yup.string()
            .min(1, 'Richiesto almeno un carattere')
    });

    const filterQuestions = (values) => {
        let questions = { questions: originalData.questions };
        for (const [key, value] of Object.entries(values)) {
            questions = {
                ...{
                    questions: questions.questions.filter(question =>
                        String(question[key]).toLowerCase()
                            .includes(value.toLowerCase())
                    )
                }
            };
        }
        if (questions.questions.length === 0) {
            toast.error("Nessuna domanda trovata", {autoClose: 2000});
        }
        setDataLoaded(questions);
    }

    return (
        <Formik
            validationSchema={ schema }
            initialValues= {
                {
                    questionTitle: '',
                    questionType: '',
                    questionLevel: '',
                    author: ''
                }
            }
            onSubmit= { (values) => filterQuestions(values) }
            onReset={ () => setDataLoaded(originalData) }
        >
            {
                ({ handleSubmit, handleReset, handleChange, touched, errors, values }) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit} className={"ml-4 mr-4"}>
                            <Row>
                                <Col xs={"auto"} md={3}>
                                    <Form.Group controlId="validationQuestionTitle">
                                        <Form.Label>
                                            Domanda
                                        </Form.Label>
                                        <Form.Control
                                            name={"questionTitle"}
                                            value={values.questionTitle}
                                            onChange={handleChange}
                                            isInvalid={touched.questionTitle && errors.questionTitle}
                                        />
                                        <Form.Control.Feedback type={"invalid"}>
                                            { errors.questionTitle }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={"auto"} md={3}>
                                    <Form.Group controlId="validationQuestionType">
                                        <Form.Label>
                                            Tipo
                                        </Form.Label>
                                        <Form.Select
                                            name={"questionType"}
                                            value={values.questionType}
                                            onChange={handleChange}
                                            isInvalid={touched.questionType && errors.questionType}
                                        >
                                            <option value="">Scegliere una tipologia di domanda da cercare</option>
                                            <option value="SINGLE">Risposta Singola</option>
                                            <option value="MULTIPLE">Risposta Multipla</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type={"invalid"}>
                                            { errors.questionType }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={"auto"} md={3}>
                                    <Form.Group controlId="validationQuestionLevel">
                                        <Form.Label>
                                            Livello
                                        </Form.Label>
                                        <Form.Select
                                            name={"questionLevel"}
                                            value={values.questionLevel}
                                            onChange={handleChange}
                                            isInvalid={touched.questionLevel && errors.questionLevel}
                                        >
                                            <option value="">Scegliere livello della domanda da cercare</option>
                                            <option value="JUNIOR">Junior</option>
                                            <option value="MIDDLE">Middle</option>
                                            <option value="SENIOR">Senior</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type={"invalid"}>
                                            { errors.questionType }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={"auto"} md={3}>
                                    <Form.Group controlId="validationQuestionAuthor">
                                        <Form.Label>
                                            Autore
                                        </Form.Label>
                                        <Form.Control
                                            name={"author"}
                                            value={values.author}
                                            onChange={handleChange}
                                            isInvalid={touched.author && errors.author}
                                        />
                                        <Form.Control.Feedback type={"invalid"}>
                                            { errors.author }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className={"text-right mt-3"}>
                                <CustomButton
                                    text={" Cerca"}
                                    type={"button"}
                                    className={"btn-success btn-md ml-2"}
                                    action={handleSubmit}
                                />
                                <CustomButton
                                    text={"Cancella"}
                                    type={"button"}
                                    className={"btn-error btn-md ml-2"}
                                    action={handleReset}
                                />
                            </div>
                        </Form>
                    </>
                )
            }
        </Formik>
    );
}

export default QuestionSearchFilter;