import MainRoute from "./components/routes/MainRoute";
import {ToastContainer} from "react-toastify";


function App() {

    return (
        <div className="layout" style={{height:'100vh'}}>
            <MainRoute />
            <ToastContainer/>
        </div>
    );
}

export default App;
