import {Card} from "react-bootstrap";
import RandCategoryQuestionCard from "./RandCategoryQuestionCard";

const HiringQuestions = (props) => {

    return (
        <>
            {
                props.loadedCategories.categories.length === 0 ?
                    <Card className={"card-primary"}>
                        <Card.Header>
                            <Card.Title>
                                Categoria domande
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text as={"div"}>
                                Nessuna categoria caricata!
                            </Card.Text>
                        </Card.Body>
                    </Card>
                :
                    <>
                        {
                            props.loadedCategories.categories.map((category) => {
                                    return(
                                            <RandCategoryQuestionCard
                                                key={"questionCard - " + category.id}
                                                category={category}
                                                remove={props.remove}/>
                                    );
                                }
                            )
                        }
                    </>
            }

        </>
    );
}

export default HiringQuestions;