import {Outlet} from "react-router-dom";
import {Sidebar} from "../sidebar/Sidebar";
import {useEffect, useRef} from "react";
import Header from "../header/Header";
import "../../styles/ui.scss";
import {screenResize} from "../../store/reducers/ui-reducer";
import {useDispatch, useSelector} from "react-redux";

const MainPage = () => {

    const dispatch = useDispatch();
    const sidebar = useSelector((state) => state.ui.sidebar)
    const sidebarRef = useRef(null);


    useEffect(() => {
        window.addEventListener("resize", () => dispatch(screenResize()));
        return () => window.removeEventListener("resize",() => dispatch(screenResize))
    }, []);

    return(
        <>
            <div className="custom-content-wrapper" style={{minHeight : "100%", width: "100vw", position:"fixed"}}>
                <Header />
                <Outlet/>
            </div>

            {sidebar && <Sidebar expandedStart={false} innerRef={sidebarRef}/>}
        </>
    );

}
export default MainPage;