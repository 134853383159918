import {Alert, Badge, Card, Modal, Table} from "react-bootstrap";
import {FaPencilAlt, FaTrash, FaUserTimes} from "react-icons/fa";
import CustomButton from "../../ui/CustomButton";
import {store} from "../../../store/store";
import {useState} from "react";
import DataPagination from "../../ui/DataPagination";
import moment from "moment";
import Restricted from "../../ui/Restricted";

const UsersTable = ({ loadedUsers, handleDeleteUser }) => {

    const [dataToShow, setDataToShow] = useState([]);
    const [userToDelete, setUserToDelete] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleModalClose = () => {
        setUserToDelete({...userToDelete, ...{}});
        setShowDeleteModal(false);
    }

    const handleModalShow = (category) => {
        setUserToDelete({...userToDelete, ...category});
        setShowDeleteModal(true);
    }

    const parseRoleBadgeColor = (role) => {
        switch(role) {
            case "ADMIN":
                return "warning";
            case "RECRUITER":
                return "danger";
            default:
                return "primary";
        }
    }

    return (
        <>
            {
                dataToShow.length === 0 ?
                    <Alert variant={"danger"}>
                        <h4>Nessuna utente trovato</h4>
                    </Alert>
                    :
            <Table responsive>
                <thead>
                <tr key={"users-table-header-row"}>
                    <th key={"users-table-header-username"}>
                        Username
                    </th>
                    <th
                        key={"users-table-header-role"}
                        style={{textAlign: "center"}}>
                        Ruolo
                    </th>
                    <th
                        key={"users-table-header-creation-date"}
                        style={{textAlign: "center"}}>
                        Data Creazione
                    </th>
                    <th key={"users-table-header-actions"}></th>
                </tr>
                </thead>
                <tbody>
                {
                        dataToShow.map((user) => {
                            return (
                                <tr key={user.id + "-" + user.username}>
                                    <td>
                                        { user.username }
                                    </td>
                                    <td
                                        style={{textAlign: "center"}}>
                                        <Badge bg={parseRoleBadgeColor(user.role)}>
                                            { user.role }
                                        </Badge>
                                    </td>
                                    <td
                                        style={{textAlign: "center"}}>
                                        { moment.utc(user.creationDate).local().format('L LT') }
                                    </td>
                                    <td
                                        style={{textAlign: "right"}}>
                                        <Restricted permission={"editUser"}>
                                            <CustomButton
                                                icon={<FaPencilAlt/>}
                                                className={"btn-info btn-sm mr-2"}
                                                path={`/users/${user.id}/edit`}
                                            />
                                        </Restricted>
                                        <Restricted permission={"deleteUser"}>
                                            <CustomButton
                                                icon={<FaUserTimes/>}
                                                className={"btn-danger btn-sm mr-2"}
                                                action={() => handleModalShow(
                                                    {
                                                        id: user.id,
                                                        username: user.username
                                                    }
                                                )}
                                            />
                                        </Restricted>
                                    </td>
                                </tr>
                            );
                        })
                }
                </tbody>
            </Table>
            }
            <Card.Footer>
                <DataPagination
                    dataLoaded={loadedUsers.users}
                    dataToShow={dataToShow}
                    setDataToShow={setDataToShow}
                />
            </Card.Footer>

            <Modal show={showDeleteModal} onHide={handleModalClose}>
                <Modal.Header closeButton className={(store.getState().ui.darkMode ? "dark-mode" : "")}>
                    <Modal.Title>Disabilitare l'utente {userToDelete.username}?</Modal.Title>
                </Modal.Header>
                <Modal.Body className={(store.getState().ui.darkMode ? "dark-mode" : "")}>
                    L'utente non potrà più operare sulla piattaforma.
                </Modal.Body>
                <Modal.Footer className={(store.getState().ui.darkMode ? "dark-mode" : "")}>
                    <CustomButton
                        text={"Chiudi"}
                        key={"close-category-confirmation"}
                        className={"btn-secondary btn-md mr-2"}
                        action={handleModalClose}
                    />
                    <CustomButton
                        text={" Elimina"}
                        key={"delete-category-confirmation"}
                        icon={<FaTrash/>}
                        className={"btn-danger btn-md mr-2"}
                        action={
                            () => {
                                handleDeleteUser(userToDelete.id);
                                handleModalClose();
                            }
                        }
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UsersTable;