import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { IconContext } from "react-icons";
import {MdKeyboardArrowDown} from "react-icons/md";



export const MenuItem = ({item, index, expanded, setExpanded, active, setActive, ready, level, path}) => {

    // Is the mouse hovering this item?
    const [hovered, setHovered] = useState(false);
    // (If this item has a subMenu) Does the subMenu have to open?
    const [openSubMenu, setOpenSubMenu] = useState(false);
    // Navigate
    const navigate = useNavigate();
    // Component's name of the icon
    const Icon = item.iconName;
    // Size of the icon (depends on the recursive level of this item)
    const iconSize = "1.5" - ("0." + level * 2) + "em";
    // Space on the left (depends on the recursive level of this item)
    const leftSpaceSize = level === 0 ? "0" : "0." + (level * 3) + "em";
    // Arrow Component
    let Arrow = MdKeyboardArrowDown;
    // Item's path
    path = path+ "/"+ item.name.replace(" ", "");

    // Opens the subMenu
    useEffect(() => {
        if(!expanded && openSubMenu)
            setOpenSubMenu(false);
    }, [expanded]);


    return (
        <>
            <div className={`boxicon-container ${level > 0 && "subMenu-item"}`} style={{left: leftSpaceSize, width: `calc(100% - ${leftSpaceSize})`}}
                 onMouseEnter={() => {
                     setHovered(true);
                     if (!expanded) {
                         setTimeout(() => { setExpanded(!expanded)} , 200)
                     }
                 }}

                 onMouseLeave={() => {
                     setHovered(false);
                 }}

                 onClick={() => {
                     if (item.subMenu) {
                         setOpenSubMenu(!openSubMenu)
                     } else {
                         navigate(`${path}`)
                     }
                 }}
            >

                <div className={`${(active === path || hovered || (active.includes(path) && level === 0)) && "hovered-container"} container-pad` } >
                    <div className={"row"}>
                        <div className={"col-3"}>
                            <IconContext.Provider value={{ color: "white", size:iconSize ,className: "global-class-name" }}>
                                <div className={"icons-div"}>
                                    <Icon />
                                </div>
                            </IconContext.Provider>


                        </div>
                        <div className={"col"}>
                            {ready && expanded && <p className={`description ${expanded && "show-description"} ${active.includes(path)  && "color-description"}`}>
                                {item.name}
                            </p>
                            }
                        </div>
                        {item.subMenu && ready && expanded &&
                            <div className={"col-3"}>
                                <IconContext.Provider value={{color: "white", size:iconSize ,className: "global-class-name" }}>
                                    <div className={"icons-div"}>
                                        {openSubMenu && <Arrow style={{transform: "rotate(180deg)"}}/>}
                                        {!openSubMenu && <Arrow />}
                                    </div>
                                </IconContext.Provider>
                            </div>
                        }
                    </div>
                </div>

            </div>

            {openSubMenu && item.subMenu.map((item, i) =>(
                <MenuItem {...{item, expanded, setExpanded, active, setActive, ready, path}}
                          level = {level+1}
                          index = {index + "." + i}
                          key={index + "." + i} />
            ))}
        </>


    );
}