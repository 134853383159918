import {Breadcrumb, Card, Col, Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import PageSection from "../../../ui/PageSection";
import {LinkContainer} from "react-router-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import {properties} from "../../../../properties";
import Page from "../../../ui/Page";
import CreateQuestionForm from "./CreateQuestionForm";

const QuestionPage = () => {

    let { selectedCategory, questionId } = useParams();
    const [questionLoaded, setQuestionLoaded] = useState({answers:[]});

    const loadQuestion = () => {
        if(questionId){
            axios.get(properties.basePath + "/questions/getById.php?questionId=" + questionId)
                .then(res => {
                    const response = res.data;
                    setQuestionLoaded({...questionLoaded, ...response});
                })
        }
    }

    useEffect(() => {loadQuestion()}, []);

    return (
        <Page>
            <PageSection title={`Categoria - ${selectedCategory} - ${questionId ? "Modifica" : "Nuova"} Domanda`}/>
            <Container fluid>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <LinkContainer to={"/database"}>
                                <Breadcrumb.Item>Database</Breadcrumb.Item>
                            </LinkContainer>
                            <LinkContainer to={"/database/" + selectedCategory}>
                                <Breadcrumb.Item>{selectedCategory}</Breadcrumb.Item>
                            </LinkContainer>
                            <Breadcrumb.Item active>{`${questionId ? "Modifica" : "Nuova"} Domanda`}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className={"card-success"}>
                            <Card.Header>
                                <Card.Title>{`${questionId ? "Modifica" : "Crea"} Domanda`}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text as={"div"}>
                                    <CreateQuestionForm
                                        selectedCategory={ selectedCategory }
                                        questionId={ questionId }
                                        questionLoaded={ questionLoaded }
                                    />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Page>
    );

}

export default QuestionPage;