import PageSection from "../../ui/PageSection";
import {Badge, Breadcrumb, Card, Col, Container, Row, Table} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";
import {useEffect, useMemo, useState} from "react";
import axios from "axios";
import {properties} from "../../../properties";
import {FaCheckCircle} from "react-icons/fa";
import {FaCircleXmark, FaPencil} from "react-icons/fa6";
import CustomButton from "../../ui/CustomButton";
import Page from "../../ui/Page";
import TablePlaceholder from "./TablePlaceholder";
import ReactQuill from "react-quill";
import moment from "moment/moment";

const ViewQuestionPage = () => {

    const { selectedCategory, questionId } = useParams();
    const [questionLoaded, setQuestionLoaded] = useState({answers:[]});
    const [isLoading, setIsLoading] = useState(true);


    const loadQuestion = () => {
        setIsLoading(true);
        axios.get(properties.basePath + "/questions/getById.php?questionId=" + questionId)
            .then(res => {
                const response = res.data;
                setQuestionLoaded({...questionLoaded, ...response});
                setIsLoading(false);
            })
    }

    useEffect(() => {loadQuestion()}, []);

    const parseLevelBadgeColor = (level) => {
        switch(level) {
            case "JUNIOR":
                return "success";
            case "MIDDLE":
                return "warning";
            case "SENIOR":
                return "danger";
            default:
                return "primary";
        }
    }

    return (
        <Page>
            <PageSection title={"Visualizza Domanda"}/>
            <Container fluid>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <LinkContainer to={"/database/"}>
                                <Breadcrumb.Item>Database</Breadcrumb.Item>
                            </LinkContainer>
                            <LinkContainer to={"/database/" + selectedCategory}>
                                <Breadcrumb.Item>{selectedCategory}</Breadcrumb.Item>
                            </LinkContainer>
                            <Breadcrumb.Item active>Visualizza Domanda</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className={"card-primary"}>
                            <Card.Header>
                                <Card.Title>Domanda</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text className={"text-lg"} style={{whiteSpace: "pre-wrap"}} as={"div"}>
                                    <div style={{fontSize: "1rem", marginBottom: "1%"}}>
                                        Livello Domanda:
                                        <Badge style={{marginLeft: "0.6%"}} bg={parseLevelBadgeColor(questionLoaded.questionLevel)}>
                                            { questionLoaded.questionLevel }
                                        </Badge>
                                    </div>
                                    <ReactQuill
                                        value={questionLoaded.question}
                                        readOnly={true}
                                        modules={{toolbar:false}}
                                    />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className={"text-right"}>
                        <CustomButton
                            text={" Modifica Domanda"}
                            icon={<FaPencil/>}
                            className={"btn-success btn-md mb-2"}
                            path={`/database/${selectedCategory}/${questionId}/edit`}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className={"card-primary"}>
                            <Card.Header>
                                <Card.Title>Risposte</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text as={"div"}>
                                    { isLoading ?
                                        <TablePlaceholder/>
                                        :
                                        questionLoaded.answers.length === 0 ?
                                            null
                                            :
                                            <Table>
                                                    <thead>
                                                    <tr>
                                                        <th className={"text-center"} style={{width:"60%"}}>
                                                            Risposta
                                                        </th>
                                                        <th className={"text-center"} style={{width:"15%"}}>
                                                            Corretta
                                                        </th>
                                                        <th className={"text-center"} style={{width:"25%"}}>
                                                            Data Ultima Modifica
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        questionLoaded.answers.map((data, index) => {
                                                            return(
                                                                <tr key={`answer-row-${index}`}>
                                                                    <td
                                                                        key={`answer-row-text-${index}`}
                                                                        style={{whiteSpace:"pre-wrap"}}>
                                                                        { data.answer }
                                                                    </td>
                                                                    <td
                                                                        key={`answer-cell-${index}`}
                                                                        className={"text-center"}>
                                                                        <h5>
                                                                            <Badge
                                                                                key={`answer-${index}`}
                                                                                bg={data.isRight ? "success" : "danger"}>
                                                                                {
                                                                                    data.isRight ?
                                                                                        <FaCheckCircle key={`answer-${index}-right`} />
                                                                                        :
                                                                                        <FaCircleXmark key={`answer-${index}-wrong`} />
                                                                                }
                                                                            </Badge>
                                                                        </h5>
                                                                    </td>
                                                                    <td className={"text-center"}>
                                                                        { moment.utc(data.lastUpdateDate).local().format('L LT') }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                    </tbody>
                                                </Table>

                                    }
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

export default ViewQuestionPage;