import axios from "axios";
import {properties} from "../../../properties";
import {store} from "../../../store/store";
import {notifyError} from "../notification-service";

export const axiosInstance = axios.create({
    baseURL: properties.basePath,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        notifyError("Errore: " + error.response.data.error);
        return Promise.reject(error);
    });


axiosInstance.interceptors.request.use(
    (config) => {
        const userId = store.getState().auth.username;

        if(userId) {
            if (config.headers) config.headers["x-user-id"] = userId;
        }

        config.headers["Content-type"] = "multipart/form-data";

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);