import {Card, Col, Container, Row} from "react-bootstrap";
import "../../../styles/pages.scss";
import Page from "../../ui/Page";
import LoginForm from "./LoginForm";

const LoginPage = () => {

    return (
        <Page classname={"fullWith"}>
            <Container className={"mt-2"} fluid>
                <Row>
                    <Col md={3} lg={4}></Col>
                    <Col md={6} lg={4}>
                        <Card className={"card-primary"}>
                            <Card.Header>
                                <Card.Title>Login</Card.Title>
                            </Card.Header>
                                <Card.Body>
                                    <Card.Text as={"div"}>
                                        <LoginForm />
                                    </Card.Text>
                                </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} lg={4}></Col>
                </Row>
            </Container>
        </Page>
    );
}

export default LoginPage;