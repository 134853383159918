import {Document, Image, Page as PagePDF, PDFViewer, StyleSheet, Text, View} from '@react-pdf/renderer';
import Page from "../../ui/Page";
import {useSelector} from "react-redux";
import ringmasterLogo from "../../../image/ringmasterLogoScrittaRossa.png";
import PageSection from "../../ui/PageSection";
import {Breadcrumb, Card, Col, Container, Row} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

const Pdf = () => {
    const MAX_QUESTIONS_FOR_PAGE = 3;
    let pdfquestions = useSelector(state => state.hiring);
    let array = pdfquestions.map(obj => obj.questions);
    let date = new Date(),
        day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1),
        year = date.getFullYear();
    const indexQuestion = {0:"a",1:"b",2:"c",3:"d"};
    let count = 0;

    const splitQuestionsForPage = () => {
        const flattenedArray = array.flatMap((innerArray) => innerArray);
        return flattenedArray.reduce((acc, obj) => {
            if (acc.length === 0 || acc[acc.length - 1].length === MAX_QUESTIONS_FOR_PAGE) {
                // Se l'array acc è vuoto o l'ultimo array in acc contiene già 3 oggetti,
                // creiamo un nuovo array e lo aggiungiamo ad acc
                acc.push([obj]);
            } else {
                // Altrimenti, aggiungiamo l'oggetto all'ultimo array in acc
                acc[acc.length - 1].push(obj);
            }
            return acc;
        }, []);
    }

    const pages = splitQuestionsForPage();

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#ffffff'
        },
        card: {
            //width: 300,
            padding: 5,
            minWidth: "95%",
            backgroundColor: "red",
            margin: "0 auto",
            borderRadius: 4,
        },
        title: {
            fontSize: 18,
            marginBottom: 10,
        },
        header:{
            flexDirection: "row"
        },
        cardHeader:{
            backgroundColor: "#007bff",
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            padding: "2% 2%",
            fontSize: 14,
            color: "white"
        },
        cardBody:{
            backgroundColor: "#ffffff",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            padding: "2% 2%",
            borderRight: "0.8px solid #007bff",
            borderLeft: "0.8px solid #007bff",
            borderBottom: "0.8px solid #007bff",
        },
        cardAnswer: {
            //float: left,
            fontSize: 12,
            fontWeight: 200,
            margin: "0 0 1px 0",
            padding: 5,
            borderBottom: "0.5px solid black"
        },
        cardAnswer2: {
            //float: left,
            fontSize: 12,
            fontWeight: 200,
            margin: "0 0 1.2px 0",
            padding: 5,
            backgroundColor: "#f2f2f2",
            borderBottom: "0.5px solid black"
        },
        text: {
            fontSize: 14,
        },
        section: {
            margin: 20,
            padding: 10,
            flexGrow: 1
        },
        logo:{
            flexGrow: 1,
            marginLeft: "2%",
            width: "25%",

        },
        date:{
            flexGrow: 1,
            marginLeft: "50%",
            marginTop: "4%",
            fontSize: 12
        }
    });

    return (
         <Page>
            <PageSection title={"Hiring PDF"} />
             <Container fluid>
                 <Row>
                     <Col>
                         <Breadcrumb>
                             <LinkContainer to={"/hiring"}>
                                 <Breadcrumb.Item>
                                     Hiring
                                 </Breadcrumb.Item>
                             </LinkContainer>
                             <Breadcrumb.Item active>
                                 Esporta Pdf
                             </Breadcrumb.Item>
                         </Breadcrumb>
                     </Col>
                 </Row>
                 <Row>
                     <Col>
                         <Card className={"card-primary"}>
                             <Card.Header>
                                 <Card.Title>
                                     PDF
                                 </Card.Title>
                             </Card.Header>
                             <Card.Body>
                                 <PDFViewer className={"pdf"}>
                                     <Document
                                         title={"Ringmaster_Hiring_quiz.pdf"}
                                         author={"Ringmaster"}
                                         subject={"prova"}>
                                         {pages.map((page, pageIndex) => {
                                             return (
                                                 <PagePDF size="A4" style={styles.page} key={`Page-${pageIndex}`}>
                                                     <View style={styles.header}>
                                                         <Image src={ringmasterLogo} style={styles.logo}/>
                                                         <Text style={styles.date}>{`Roma, ${day}/${month}/${year}`}</Text>
                                                     </View>
                                                     {page.map(element => {
                                                         count++;
                                                         return (
                                                             <View style={styles.section} key={`Section-${element.id}-${element.question}`}>
                                                                 <Text style={styles.cardHeader} key={`Question-${element.id}-${element.question}`}>{`${count} - ${element.questionTitle}`}</Text>
                                                                 <View style={styles.cardBody} key={`Section-${element.id}-${element.question}`}>
                                                                     {
                                                                         element.answers.map((answer,index) => {
                                                                             return(

                                                                                 <Text style={index % 2 === 0 ? styles.cardAnswer : styles.cardAnswer2} key={`Answer-${answer.answerId}-${element.id}`}>
                                                                                     {`${indexQuestion[index]}) ${answer.answer}`}
                                                                                 </Text>
                                                                             )
                                                                         })
                                                                     }
                                                                 </View>
                                                             </View>
                                                         )
                                                     })}
                                                 </PagePDF>
                                             )
                                         })}
                                     </Document>
                                 </PDFViewer>
                             </Card.Body>
                         </Card>
                     </Col>
                 </Row>
             </Container>
         </Page>
    );
}

export default Pdf;